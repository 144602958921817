<template>
  <body>
    <v-container
      fluid
      class="pa-0"
    >
      <div class="sidebar">
        <div
          class="sidebar-inner"
          style="position: fixed !important"
        >
          <div class="list">
            <h2>
              {{ $t('introduction.guide') }}
            </h2>
            <ul class="menu-root">
              <li>
                <ul class="menu-sub">
                  <li>
                    <a
                      href="#"
                      class="sidebar-link"
                      target="_blank"
                    >{{ $t('introduction.introduction') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#login"
                    >{{ $t('introduction.loggingIn') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#ebay-leko"
                    ><strong>{{ $t('introduction.ebayLeko') }}</strong></a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#home"
                    >{{ $t('introduction.home') }}</a>
                  </li>

                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#insert-parts"
                    >{{ $t('introduction.insertPartsTitle') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#inventory"
                    >{{ $t('introduction.inventoryTitle') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#damaged-parts"
                    >{{ $t('introduction.damagedPartsTitle') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#lost-parts"
                    >{{ $t('introduction.lostPartsTitle') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#recycled-parts"
                    >{{ $t('introduction.recycledPartsTitle') }}</a>
                  </li>

                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#movements"
                    >{{ $t('introduction.movementsTitle') }}</a>
                  </li><li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#manage-sales"
                    >
                      {{ $t('introduction.manageSalesTitle') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#ordered-parts"
                    >{{ $t('introduction.orderedPartsTitle') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#reservation"
                    >{{ $t('introduction.reservationTitle') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#vehicle-entry"
                    >{{ $t('introduction.vehicleEntryTitle') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#order-leko"
                    >{{ $t('introduction.orderViaLekoShop') }}</a>
                  </li>
                  <li>
                    <a
                      class="sidebar-link"
                      data-scroll=""
                      href="#optin"
                    ><strong>{{ $t('introduction.optin') }}</strong></a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="content guide with-sidebar index-guide">
        <h1>{{ $t('introduction.introduction') }}</h1>

        <h2
          id="What-is-LekoTech"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#What-is-LekoTech"
            class="headerlink"
            title="What is LekoTech?"
            data-scroll=""
          > {{ $t('introduction.whatIsLekoTech') }}</a>
        </h2>
        <p>
          {{ $t('introduction.whyLekoTech.description') }}
        </p>
        <div class="vue-mastery">
          <a
            href="https://youtu.be/kCbknR2R6AU"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{ $t('introduction.watch_video_leko_dashboard') }}</a>
        </div>

        <h2
          id="home"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#home"
            class="headerlink"
            title="Home"
            data-scroll=""
          >{{ $t('home.home') }}</a>
        </h2>
        <img
          src="@/assets/leko/home.png"
          alt="Inventory Image"
        >
        <p>{{ $t('introduction.homePageDescription') }}</p>

        <ol>
          <li>
            <h3>{{ $t('introduction.carsSectionTitle') }}</h3>
            <ul>
              <li><strong>{{ $t('introduction.carsCard.label') }}:</strong> {{ $t('introduction.carsCard.description') }}</li>
              <li><strong>{{ $t('introduction.carsAction.label') }}:</strong> {{ $t('introduction.carsAction.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.priceSectionTitle') }}</h3>
            <ul>
              <li><strong>{{ $t('introduction.priceCard.label') }}:</strong> {{ $t('introduction.priceCard.description') }}</li>
              <li><strong>{{ $t('introduction.priceAction.label') }}:</strong> {{ $t('introduction.priceAction.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.partsSectionTitle') }}</h3>
            <ul>
              <li><strong>{{ $t('introduction.partsCard.label') }}:</strong> {{ $t('introduction.partsCard.description') }}</li>
              <li><strong>{{ $t('introduction.partsAction.label') }}:</strong> {{ $t('introduction.partsAction.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.salesSectionTitle') }}</h3>
            <ul>
              <li><strong>{{ $t('introduction.salesCard.label') }}:</strong> {{ $t('introduction.salesCard.description') }}</li>
              <li><strong>{{ $t('introduction.salesAction.label') }}:</strong> {{ $t('introduction.salesAction.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.userHistoryTitle') }}</h3>
            <ul>
              <li><strong>{{ $t('introduction.userHistoryAction.label') }}:</strong> {{ $t('introduction.userHistoryAction.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.filterActivityTitle') }}</h3>
            <ul>
              <li><strong>{{ $t('introduction.filterActivityCustomizeView.label') }}:</strong> {{ $t('introduction.filterActivityCustomizeView.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.chartsTitle') }}</h3>
            <ul>
              <li><strong>{{ $t('introduction.chartsVisualRepresentation.label') }}:</strong> {{ $t('introduction.chartsVisualRepresentation.description') }}</li>
              <li><strong>{{ $t('introduction.chartsInteractive.label') }}:</strong> {{ $t('introduction.chartsInteractive.description') }}</li>
              <li><strong>{{ $t('introduction.chartsFilterOptions.label') }}:</strong> {{ $t('introduction.chartsFilterOptions.description') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/@LekoTech"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{$t('introduction.watch_video_leko')}}</a>
        </div>

        <h2
          id="login"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#login"
            class="headerlink"
            title="Log in"
            data-scroll=""
          >
            {{ $t('introduction.loggingIn') }}</a>
        </h2>
        <img
          src="@/assets/leko/login.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('login.accessTitle') }}</h3>
            <ul>
              <li>{{ $t('login.accessDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('login.credentialsTitle') }}</h3>
            <ul>
              <li>{{ $t('login.credentialsDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('login.languageSelectionTitle') }}</h3>
            <ul>
              <li>{{ $t('login.languageSelectionDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('login.passwordVisibilityTitle') }}</h3>
            <ul>
              <li>{{ $t('login.passwordVisibilityDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('login.submitTitle') }}</h3>
            <ul>
              <li>{{ $t('login.submitDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('login.successTitle') }}</h3>
            <ul>
              <li>{{ $t('login.successDescription') }}</li>
            </ul>
          </li>
        </ol>

        <h3>{{ $t('login.languagePreferenceTitle') }}</h3>

        <ol>
          <li>
            <h3>{{ $t('login.defaultLanguageTitle') }}</h3>
            <ul>
              <li>{{ $t('login.defaultLanguageDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('login.changeLanguageTitle') }}</h3>
            <ul>
              <li>{{ $t('login.changeLanguageDescription') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/@LekoTech"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{$t('introduction.watch_video_leko')}}</a>
        </div>

        <h2
          id="ebay-leko"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#ebay-leko"
            class="headerlink"
            title="Log in"
            data-scroll=""
          >
            {{ $t('introduction.ebayLeko') }}</a>
        </h2>
        <img
          src="@/assets/leko/ebayLeko.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('introduction.ebay_leko.options') }}</h3>
            <ul>
              <li> {{ $t('introduction.ebay_leko.options_description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.ebay_leko.check_ebay') }}</h3>
            <ul>
              <li> {{ $t('introduction.ebay_leko.check_ebay_description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.ebay_leko.edit_options') }}</h3>
            <ul>
              <li> {{ $t('introduction.ebay_leko.edit_options_description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.ebay_leko.delete_options') }}</h3>
            <ul>
              <li> {{ $t('introduction.ebay_leko.delete_options_description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.ebay_leko.ebay_conditions') }}</h3>
            <ul>
              <li> {{ $t('introduction.ebay_leko.ebay_conditions_description') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://youtu.be/sd2f5ONmO9E"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{ $t('introduction.watch_video_leko_ebay') }} 1</a>
        </div>
        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/watch?v=ULo9jNaKrg0"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{ $t('introduction.watch_video_leko_ebay') }} 2</a>
        </div>
        <h2 id="insert-parts">
          <a
            href="#insert-parts"
            class="headerlink"
            title="Insert Parts"
            data-scroll=""
          > {{ $t('introduction.insertPartsTitle') }}
          </a>
        </h2>
        <img
          src="@/assets/leko/insertParts.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('introduction.insertParts.searchTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.insertParts.searchDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.insertParts.qrScannerTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.insertParts.qrScannerDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.insertParts.approveDeleteTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.insertParts.approveDeleteDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.insertParts.insertEmptyPartsTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.insertParts.insertEmptyPartsDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.insertParts.insertedPartsTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.insertParts.insertedParts.dataTable') }}</li>
              <li>{{ $t('introduction.insertParts.insertedParts.functionality') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.insertParts.editFunctionalityTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.insertParts.editFunctionalityDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.insertParts.emptyPartsTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.insertParts.emptyParts.accessQRCodes') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://youtu.be/Np1Mgvi3PVI"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{ $t('introduction.watch_video_leko_insert_parts') }}</a>
        </div>

        <h2 id="inventory">
          <a
            href="#inventory"
            class="headerlink"
            title="Inventory"
            data-scroll=""
          >{{ $t('introduction.inventoryTitle') }}
          </a>
        </h2>
        <img
          src="@/assets/leko/inventory.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('introduction.inventory.detailsFunctionalityTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.detailsFunctionalityDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.actionsFunctionalityTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.actionsFunctionalityDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.editFunctionalityTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.editFunctionalityDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.viewCarFunctionalityTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.viewCarFunctionalityDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.shareFunctionalityTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.shareFunctionalityDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.searchByTitleTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.searchByTitleDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.searchMultipleTitlesTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.searchMultipleTitlesDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.searchByIDTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.searchByIDDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.searchByPRATitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.inventory.searchByPRADescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.searchOtherFieldsTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.inventory.searchOtherFieldsDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.advancedSearchToggleTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.inventory.advancedSearchToggleDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.openQRScannerTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.openQRScannerDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.exportAsPDFTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.inventory.exportAsPDFDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.syncPriceTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.syncPriceDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.exportAsExcelTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.inventory.exportAsExcelDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.exportAsArchiveTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.exportAsArchiveDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.shareTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.shareDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.deleteTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.deleteDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.toggleAdvancedSearchTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.toggleAdvancedSearchDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.clearSearchFieldsTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.inventory.clearSearchFieldsDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.inventory.searchMethodTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.inventory.searchMethodDescription') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/@LekoTech"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{$t('introduction.watch_video_leko')}}</a>
        </div>

        <h2 id="damaged-parts">
          <a
            href="#damaged-parts"
            class="headerlink"
            title="Damaged Parts"
            data-scroll=""
          >{{ $t('introduction.damagedPartsTitle') }}
          </a>
        </h2>
        <img
          src="@/assets/leko/damageParts.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('introduction.damagedParts.searchTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.damagedParts.searchDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.damagedParts.searchMultipleTitlesTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.damagedParts.searchMultipleTitlesDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.damagedParts.searchByIDTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.damagedParts.searchByIDDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.damagedParts.searchOtherFieldsTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.damagedParts.searchOtherFieldsDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.damagedParts.advancedSearchToggleTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.damagedParts.advancedSearchToggleDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.damagedParts.clearSearchFieldsTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.damagedParts.clearSearchFieldsDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.damagedParts.searchMethodTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.damagedParts.searchMethodDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.damagedParts.exportToExcelTitle') }}</h3>
            <ul>
              <li> {{ $t('introduction.damagedParts.exportToExcelDescription') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/@LekoTech"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{$t('introduction.watch_video_leko')}}</a>
        </div>

        <h2 id="lost-parts">
          <a
            href="#lost-parts"
            class="headerlink"
            title="Lost Parts"
            data-scroll=""
          >{{ $t('introduction.lostPartsTitle') }}
          </a>
        </h2>
        <img
          src="@/assets/leko/lostParts.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('introduction.lostParts.searchByTitle.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.searchByTitle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.lostParts.searchMultipleTitles.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.searchMultipleTitles.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.lostParts.searchByID.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.searchByID.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.lostParts.searchOtherFields.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.searchOtherFields.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.lostParts.advancedSearchToggle.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.advancedSearchToggle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.lostParts.clearSearchFields.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.clearSearchFields.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.lostParts.searchMethod.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.searchMethod.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.lostParts.exportToExcel.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.exportToExcel.description') }}</li>
            </ul>
          </li>
        </ol>


        <ol>
          <li>
            <h3>"{{ $t('introduction.lostParts.dataTableFunctionality.applicabilityFunctionality.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.dataTableFunctionality.applicabilityFunctionality.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.lostParts.dataTableFunctionality.actionsFunctionality.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.lostParts.dataTableFunctionality.actionsFunctionality.description') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/@LekoTech"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{$t('introduction.watch_video_leko')}}</a>
        </div>

        <h2 id="recycled-parts">
          <a
            href="#recycled-parts"
            class="headerlink"
            title="Recycled parts"
            data-scroll=""
          >{{ $t('introduction.recycledPartsTitle') }}
          </a>
        </h2>
        <img
          src="@/assets/leko/recycledParts.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('introduction.recycledParts.searchByTitle.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.searchByTitle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.recycledParts.searchByID.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.searchByID.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.recycledParts.searchMultipleTitles.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.searchMultipleTitles.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.recycledParts.searchOtherFields.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.searchOtherFields.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.recycledParts.advancedSearchToggle.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.advancedSearchToggle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.recycledParts.clearSearchFields.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.clearSearchFields.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.recycledParts.searchMethod.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.searchMethod.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.recycledParts.exportToExcel.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.exportToExcel.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.recycledParts.applicabilityFunctionality.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.applicabilityFunctionality.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.recycledParts.actionsFunctionality.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.recycledParts.actionsFunctionality.description') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/@LekoTech"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{$t('introduction.watch_video_leko')}}</a>
        </div>

        <h2
          id="movements"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#movements"
            class="headerlink"
            title="Movements"
            data-scroll=""
          >
            {{ $t('introduction.movementsTitle') }}</a>
        </h2>
        <img
          src="@/assets/leko/movements.png"
          alt="Inventory Image"
        >
        <p>{{ $t('introduction.movements.toolbarDescription') }}</p>

        <ol>
          <li>
            <h3>{{ $t('introduction.movements.searchByTitle.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.movements.searchByTitle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.movements.searchByID.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.movements.searchByID.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.movements.searchMultipleTitles.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.movements.searchMultipleTitles.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.movements.searchOtherFields.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.movements.searchOtherFields.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.movements.advancedSearchToggle.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.movements.advancedSearchToggle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.movements.clearSearchFields.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.movements.clearSearchFields.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.movements.searchMethod.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.movements.searchMethod.description') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/@LekoTech"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{$t('introduction.watch_video_leko')}}</a>
        </div>


        <h2
          id="manage-sales"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#manage-sales"
            class="headerlink"
            title=""
            data-scroll=""
          >
            {{ $t('introduction.manageSalesTitle') }}</a>
        </h2>
        <img
          src="@/assets/leko/manageSale.png"
          alt="Inventory Image"
        >

        <ol>
          <li>
            <h3>{{ $t('introduction.manageSales.toolbarTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.manageSales.searchByTitle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.manageSales.searchByID.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.manageSales.searchByID.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.manageSales.searchOtherFields.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.manageSales.searchOtherFields.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.manageSales.advancedSearchToggle.title') }}</h3>
            <ul>
              <li> {{ $t('introduction.manageSales.advancedSearchToggle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.manageSales.clearSearchFields.title') }}</h3>
            <ul>
              <li> {{ $t('introduction.manageSales.clearSearchFields.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.manageSales.searchMethod.title') }}</h3>
            <ul>
              <li> {{ $t('introduction.manageSales.searchMethod.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.manageSales.exportToExcel.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.manageSales.exportToExcel.description') }}</li>
            </ul>
          </li>
        </ol>

        <h3>{{ $t('introduction.manageSales.datatableTitle') }}</h3>

        <ol>
          <li>
            <h3>{{ $t('introduction.manageSales.applicabilityFunctionality.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.manageSales.applicabilityFunctionality.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.manageSales.printFunctionality.title') }}</h3>
            <ul>
              <li> {{ $t('introduction.manageSales.printFunctionality.description') }}</li>
            </ul>
          </li>
        </ol>

        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/@LekoTech"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{$t('introduction.watch_video_leko')}}</a>
        </div>


        <h2
          id="ordered-parts"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#ordered-parts"
            class="headerlink"
            title=""
            data-scroll=""
          >
            {{ $t('introduction.orderedPartsTitle') }}</a>
        </h2>
        <img
          src="@/assets/leko/orderedParts.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('introduction.orderedParts.searchTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderedParts.searchTitleDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderedParts.searchMultipleTitlesTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderedParts.searchMultipleTitlesDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderedParts.searchByIDTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderedParts.searchByIDDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderedParts.searchOtherFieldsTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderedParts.searchOtherFieldsDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderedParts.advancedSearchToggleTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderedParts.advancedSearchToggleDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderedParts.clearSearchFieldsTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderedParts.clearSearchFieldsDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderedParts.searchMethodTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderedParts.searchMethodDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderedParts.exportToExcelTitle') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderedParts.exportToExcelDescription') }}</li>
            </ul>
          </li>
        </ol>

        <div class="vue-mastery">
          <a
            href="https://youtu.be/J6qhUKw5WOQ"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{ $t('introduction.watch_video_leko_orders') }} </a>
        </div>

        <h2
          id="reservation"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#reservation"
            class="headerlink"
            title=""
            data-scroll=""
          >
            {{ $t('introduction.reservationTitle') }}</a>
        </h2>
        <img
          src="@/assets/leko/reservation.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3> {{ $t('introduction.newReservation.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.newReservation.description') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/@LekoTech"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{$t('introduction.watch_video_leko')}}</a>
        </div>

        <h2
          id="vehicle-entry"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#vehicle-entry"
            class="headerlink"
            title="Vehicle Entry"
            data-scroll=""
          >
            {{ $t('introduction.vehicleEntryTitle') }}</a>
        </h2>
        <img
          src="@/assets/leko/vehicleEntry.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('introduction.vehicleEntry.toolbar.vehicleInventory.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.toolbar.vehicleInventory.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.toolbar.vehicleHistory.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.toolbar.vehicleHistory.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.toolbar.openQRScanner.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.toolbar.openQRScanner.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.toolbar.addNewVehicle.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.toolbar.addNewVehicle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.toolbar.selectVehicle.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.toolbar.selectVehicle.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.toolbar.clearFunctionality.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.toolbar.clearFunctionality.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.toolbar.searchFunctionality.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.toolbar.searchFunctionality.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.datatable.shareFunctionality.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.datatable.shareFunctionality.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.datatable.carDetails.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.datatable.carDetails.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.datatable.editCar.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.datatable.editCar.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.datatable.generalShipment.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.datatable.generalShipment.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.datatable.downloads.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.datatable.downloads.description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.vehicleEntry.datatable.clearFunctionality.title') }}</h3>
            <ul>
              <li>{{ $t('introduction.vehicleEntry.datatable.clearFunctionality.description') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://youtu.be/fF35BnnCSbU"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{ $t('introduction.watch_video_leko_vehicle') }}</a>
        </div>
        <h2
          id="order-leko"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#order-leko"
            class="headerlink"
            title="Order Leko"
            data-scroll=""
          >
            {{ $t('introduction.orderViaLekoShop') }}</a>
        </h2>
        <img
          src="@/assets/leko/orderViaLekoShop.png"
          alt="Image"
        >
        <ol>
          <li>
            <h3>
              {{ $t('introduction.orderLekoShop.search_options') }}
              {{ $t('introduction.orderLekoShop.searchTitle') }}, {{ $t('introduction.orderLekoShop.searchMultipleTitlesTitle') }},
              {{ $t('introduction.orderLekoShop.searchByIDTitle') }}, {{ $t('introduction.orderLekoShop.searchOtherFieldsTitle') }}
            </h3>
            <ul>
              <li>{{ $t('introduction.orderLekoShop.searchDescription') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderLekoShop.buy_options') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderLekoShop.buy_options_description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderLekoShop.add_to_cart') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderLekoShop.add_to_cart_description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderLekoShop.buy_btn') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderLekoShop.buy_btn_description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderLekoShop.complete_order') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderLekoShop.complete_order_description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.orderLekoShop.check_order') }}</h3>
            <ul>
              <li>{{ $t('introduction.orderLekoShop.check_order_description') }}</li>
            </ul>
          </li>
        </ol>

        <div class="vue-mastery">
          <a
            href="https://www.youtube.com/watch?v=LWXMjvpZeEo"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{ $t('introduction.watch_video_leko_shop') }}</a>
        </div>

        <h2
          id="optin"
          tabindex="-1"
          style="outline: currentcolor none medium;"
        >
          <a
            href="#optin"
            class="headerlink"
            title="optin"
            data-scroll=""
          >
            {{ $t('introduction.optin') }}</a>
        </h2>
        <img
          src="@/assets/leko/optin.png"
          alt="Inventory Image"
        >
        <ol>
          <li>
            <h3>{{ $t('introduction.optin') }}</h3>
            <ul>
              <li>{{ $t('introduction.optin_page.optin_description') }}</li>
            </ul>
          </li>

          <li>
            <h3>{{ $t('introduction.optin_page.check_client') }}</h3>
            <ul>
              <li>{{ $t('introduction.optin_page.check_client_description') }}</li>
            </ul>
          </li>
        </ol>
        <div class="vue-mastery">
          <a
            href="https://youtu.be/K_kKyuHhysY"
            target="_blank"
            rel="sponsored noopener"
            title=""
          >{{ $t('introduction.watch_video_leko_optin') }}</a>
        </div>
      </div>
    </v-container>
  </body>
</template>

<style scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
}

h2 {
  color: #333;
}

ol, ul {
  margin-top: 0;
}

li {
  margin-bottom: 10px;
}
a {
  text-decoration: none;
  color: #304455;
}
em {
  color: #4f5959;
}
p {
  word-spacing: 0.05em;
}
a.button,
input.button {
  padding: 0.75em 2em;
  border-radius: 2em;
  display: inline-block;
  color: #fff;
  background-color: #4fc08d;
  transition: all 0.15s ease;
  box-sizing: border-box;
  border: 1px solid #4fc08d;
}
a.button.white,
input.button.white {
  background-color: #fff;
  /* color: #42b983; */
}
a.button.gray,
input.button.gray {
  background-color: #f6f6f6;
  color: #4f5959;
  border-color: #f6f6f6;
}
#demo,
.demo,
.content .demo {
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 25px 35px;
  margin-top: 1em;
  margin-bottom: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: auto;
}
#demo h1,
.demo h1,
.content .demo h1 {
  margin: 0 0 0.5em;
  font-size: 1.8em;
}
#demo h2,
.demo h2,
.content .demo h2 {
  padding: 0;
  border: none;
}
#demo h2,
.demo h2,
.content .demo h2,
#demo h3,
.demo h3,
.content .demo h3,
#demo h4,
.demo h4,
.content .demo h4,
#demo h5,
.demo h5,
.content .demo h5,
#demo h6,
.demo h6,
.content .demo h6 {
  margin: 1em 0;
}
#demo ul,
.demo ul,
.content .demo ul,
#demo ul:first-child,
.demo ul:first-child,
.content .demo ul:first-child,
#demo ul:last-child,
.demo ul:last-child,
.content .demo ul:last-child,
#demo li,
.demo li,
.content .demo li {
  color: #304455;
}
#demo li.done,
.demo li.done,
.content .demo li.done {
  color: #4f5959;
  text-decoration: line-through;
}
#demo p,
.demo p,
.content .demo p {
  margin: 0 !important;
  padding: 0 !important;
}
#demo textarea,
.demo textarea,
.content .demo textarea {
  width: 100%;
  resize: vertical;
}
#demo > :first-child,
.demo > :first-child,
.content .demo > :first-child {
  margin-top: 0;
}
#demo > :last-child,
.demo > :last-child,
.content .demo > :last-child {
  margin-bottom: 0;
}
ul#demo li,
ul.demo li {
  margin-left: 1.5em;
}
@media screen and (max-width: 900px) {
  #demo,
  .demo {
    margin-left: 0;
  }
}
.content.guide[class*="migration"] h2 > sup,
.content.guide[class*="migration"] h3 > sup {
  margin-left: 0.3em;
  color: #b9465c;
}
.content.guide[class*="migration"] .upgrade-path {
  margin-top: 2em;
  padding: 2em;
  background: rgba(73,195,140,0.1);
  border-radius: 2px;
}
.content.guide[class*="migration"] .upgrade-path > h4 {
  margin-top: 0;
}
.content.guide[class*="migration"] .upgrade-path > p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.sidebar {
  position: absolute;
  top: 61px;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}
.sidebar h2 {
  margin-top: 0.2em;
}
.sidebar ul {
  list-style-type: none;
  margin: 0;
  line-height: 1.5em;
  padding-left: 1em;
}
.sidebar li {
  margin-top: 0.5em;
}
.sidebar .sidebar-inner {
  width: 260px;
  padding: 35px 0px 60px 20px;
}
.sidebar .version-select {
  vertical-align: middle;
  margin-left: 5px;
}
.sidebar .menu-root {
  padding-left: 0;
}
.sidebar .sidebar-link {
  color: #4f5959;
}
.sidebar .sidebar-link.current {
  font-weight: 600;
  color: #42b983;
}
.sidebar .sidebar-link.new:after {
  content: "NEW";
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  background-color: #42b983;
  line-height: 14px;
  padding: 0 4px;
  border-radius: 3px;
  margin-left: 5px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.sidebar .sidebar-link:hover {
  border-bottom: 2px solid #42b983;
}
@media screen and (max-width: 900px) {
  .sidebar {
    position: fixed;
    background-color: #f9f9f9;
    height: 100%;
    top: 40px;
    left: 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    transition: all 0.4s cubic-bezier(0.4, 0, 0, 1);
    -webkit-transform: translate(-280px, 0);
    transform: translate(-280px, 0);
  }
  .sidebar .sidebar-inner {
    padding: 60px 10px 30px 20px;
    box-sizing: border-box;
  }
  .sidebar .sidebar-inner-index {
    padding: 10px 10px 10px 20px;
  }
  .sidebar.open {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.modal {
  box-sizing: border-box;
  display: none;
  position: fixed;
  width: 75%;
  height: auto;
  padding: 0.5em;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.modal.open {
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
}
.modal-text {
  margin-bottom: 0.5em;
  text-align: center;
}
.modal-text > a {
  color: #42b983;
  font-weight: 600;
}
@media screen and (max-width: 400px) {
  .modal {
    width: 98%;
  }
}
.vue-mastery {
  background-color: #e7ecf3;
  padding: 1em 1.25em;
  border-radius: 2px;
  color: #486491;
  position: relative;
  margin-top: 1em;
}
.vue-mastery a {
  color: #486491 !important;
  position: relative;
  padding-left: 36px;
}
.vue-mastery a:before {
  content: '';
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  top: -5px;
  left: -4px;
  border-radius: 50%;
  background-color: #73abfe;
}
.vue-mastery a:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 5px;
  left: 8px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #fff;
}
.content {
  position: relative;
  padding: 35px 0;
  max-width: 700px;
  margin: 0 auto;
  padding-left: 50px;
}
.content a.button {
  font-size: 0.9em;
  color: #fff;
  margin: 0.2em 0;
  width: 200px;
  text-align: center;
  padding: 12px 24px;
  display: inline-block;
  vertical-align: middle;
}
.content img {
  max-width: 100%;
}
.content span.info {
  font-size: 0.85em;
  display: inline-block;
  vertical-align: middle;
  width: 280px;
  margin-left: 20px;
}
.content h1 {
  margin: 0 0 1em;
}
.content h2,
.content h3 {
  pointer-events: none;
}
.content h2 a,
.content h3 a,
.content h2 button,
.content h3 button {
  pointer-events: auto;
  color: #273849;
}
.content h2:before,
.content h3:before {
  content: "";
  display: block;
  margin-top: -96px;
  height: 96px;
  visibility: hidden;
}
.content h2 {
  margin: 45px 0 0.8em;
  padding-bottom: 0.7em;
  border-bottom: 1px solid #ddd;
}
.content h3 {
  line-height: 1.2;
  position: relative;
}
.content h3 > a:before {
  content: "#";
  color: #42b983;
  position: absolute;
  left: -0.7em;
  margin-top: -0.05em;
  padding-right: 0.5em;
  font-size: 1.2em;
  line-height: 1;
  font-weight: bold;
}
.content p,
.content ul,
.content ol {
  line-height: 1.6em;
  margin: 1.2em 0 -1.2em;
  padding-bottom: 1.2em;
  position: relative;
  z-index: 1;
}
.content ul,
.content ol {
  padding-left: 1.5em;
  position: inherit;
}
.content ul ul,
.content ol ul,
.content ul ol,
.content ol ol {
  margin: 0;
}
.content a {
  color: #42b983;
  font-weight: 600;
}
.content iframe {
  margin: 1em 0;
}
.content p.tip,
.content p.success {
  padding: 12px 24px 12px 30px;
  margin: 2em 0;
  border-left-width: 4px;
  border-left-style: solid;
  background-color: #f8f8f8;
  position: relative;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.content p.tip:before,
.content p.success:before {
  position: absolute;
  top: 14px;
  left: -12px;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-family: "Dosis", "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
}
.content p.tip em,
.content p.success em {
  color: #304455;
}
.content p.tip {
  border-left-color: #f66;
}
.content p.tip:before {
  content: "!";
  background-color: #f66;
}
.content p.success {
  border-left-color: #42b983;
}
.content p.success:before {
  content: "\f00c";
  font-family: FontAwesome;
  background-color: #42b983;
}
.content p.warning {
  border-left-color: #ffa500;
}
.content p.warning:before {
  background-color: #ffa500;
}
.content p.v3-warning {
  margin-top: 0;
}

@media screen and (max-width: 1300px) {
  .content.with-sidebar {
    margin-left: 290px;
  }
  #ad {
    z-index: 1;
    position: relative;
    padding: 0;
    bottom: 0;
    right: 0;
    float: right;
    padding: 0 0 20px 30px;
  }
}
@media screen and (max-width: 900px) {
  body {
    -webkit-text-size-adjust: none;
    font-size: 14px;
  }
  .content {
    padding-left: 0;
  }
  .content.with-sidebar {
    margin: auto;
  }
  .content h2:before,
  .content h3:before {
    content: "";
    display: block;
    margin-top: -75px;
    height: 75px;
    visibility: hidden;
  }
}
@media screen and (max-width: 560px) {
  iframe {
    margin: 0 !important;
  }
}
>>> .menu-sub a {
  color: #425364!important;
}

</style>
<script>
import ImgCarousel from '@/components/imgCarousel';
import Images from '@/components/PartRequests/images';

export default {
  components: { Images, ImgCarousel },
};
</script>
