<template>
  <div>
    <v-dialog
      v-model="googleDetailsModal"
      persistent
      width="550"
    >
      <v-toolbar
        flat
        dark
        style="background-color: #D32F2F"
      >
        <v-toolbar-title>{{ $t('institutions.table.google_details') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          outline
          icon
          small
          color="white"
          @click="closeGoogleDetailsModal"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="pa-3">
        <v-progress-linear
          v-if="loading"
          color="#D32F2F"
          indeterminate
          rounded
          height="6"
        />
        <div class="pb-3">
          <v-alert
            :value="true"
            type="error"
            border="bottom"
            outline
            prominent
            text
          >
            {{ $t('institutions.form.google_condition') }}
          </v-alert>
        </div>
        <div class="pb-3">
          <v-text-field
            v-model="item.googleId"
            :label="$t('institutions.form.googleId')"
            color="red darken-1"
            prepend-icon="security"
          />
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="gray darken-1"
            @click.native="closeGoogleDetailsModal"
          >
            {{ $t('institutions.form.close') }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            :disabled="checkGoogleDetails"
            @click="saveGoogleDetails"
          >
            {{ $t('institutions.form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="googleErrorDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          :value="true"
          type="error"
        >
          {{ googleResponse }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="googleErrorDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="alert"
      max-width="500"
    >
      <v-alert
        v-model="alert"
        type="success"
        dismissible
        :value="true"
      >
        {{ $t('institutions.form.verification_success') }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="success"
      max-width="300"
    >
      <v-alert
        v-model="success"
        type="success"
        dismissible
        :value="true"
      >
        {{ $t('institutions.form.success') }}
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {
  },
  data: () => ({
    institution_id: null,
    googleDetailsModal: false,
    item: {
      googleId: '',
    },
    googleResponse: null,
    googleErrorDialog: false,
    loading: false,
    alert: false,
    inst: null,
    loadingSave: false,
    institutionDetails: null,
    // baseUrl: 'http://localhost:3000',
    baseUrl: 'https://api.lekotech.com',
    rules: {
      required: [v => !!v || 'Please fill this field'],
    },
    valid: false,
    success: false,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    checkGoogleDetails() {
      return !this.item.googleId;
    },
  },
  watch: {
    valid(value) {
      console.log('valid', value);
      this.$emit('valid', value);
    },
  },
  methods: {
    openGoogleDetailsModal(institution) {
      this.institution_id = institution._id;
      this.inst = institution;
      this.item.googleId = institution.googleMerchantId || '';
      this.googleDetailsModal = true;
    },
    saveGoogleDetails() {
      console.log('item', this.item);
      this.loading = true;
      axios.put(
        `${this.baseUrl}/institution/edit-google-merchant-details/${this.institution_id}`,
        { googleMerchantId: this.item.googleId }, {
          headers: { authorization: localStorage.token },
        },
      )
        .then((response) => {
          console.log('res:', response.data);
          if (response && response.data && response.data.errorMsg) {
            this.googleResponse = response.data.errorMsg;
            this.googleErrorDialog = true;
          } else {
            this.alert = true;
            this.googleDetailsModal = false;
            this.item.googleId = response.data && response.data.googleMerchantId ? response.data.googleMerchantId : '';
            setTimeout(() => { this.alert = false; }, 3000);
            this.$emit('fetchInstitutionDetails');
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Error adding items to eBay:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeGoogleDetailsModal() {
      this.googleDetailsModal = false;
      this.$emit('fetchInstitutionDetails');
    },
  },
};
</script>
