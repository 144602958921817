<template>
  <v-container
    fluid
    class="pa-0 col-12 justify-center"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('leko_employees.ebay_shipping_price.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
    </v-toolbar>

    <!---->
    <div class="col-12 text-center justify-center">
      <v-layout
        column
        justify-center
        wrap
      >
        <v-flex xs12>
          <v-card flat>
            <v-layout
              row
              wrap
            >
              <!--
            <v-flex xs12>
              <v-alert
                :value="alert.value"
                :type="alert.type"
              >
                {{ alert.message }}
              </v-alert>
            </v-flex>
            -->

              <v-flex
                xs12
                lg8
              >
                <v-container grid-list-md>
                  <!-- PART DESCRIPTION -->
                  <v-toolbar
                    flat
                    dark
                  >
                    <v-toolbar-title>
                      {{ $t('leko_employees.ebay_shipping_price.part_description') }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-layout
                    mb-2
                    wrap
                    align-center
                    justify-center
                  >
                    <v-flex
                      xs12
                      sm7
                      md8
                    >
                      <v-autocomplete
                        v-model="title"
                        :loading="loadingTitle"
                        :items="titles"
                        :search-input.sync="searchTitle"
                        :label="$t('parts.inventory.search')"
                        :filter="() => true"
                        :item-text="`title.${$store.getters.language}`"
                        solo
                        color="red darken-1"
                        append-icon="search"
                        single-line
                        hide-details
                        hide-no-data
                        return-object
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm5
                      md4
                    >
                      <v-text-field
                        v-model="price"
                        type="number"
                        oninput="validity.valid||(value='');"
                        :label="$t('ebayShippingReferencePrice')"
                        color="red darken-1"
                        class="mt-3"
                        outlined
                      />
                    </v-flex>
                    <v-flex
                      v-if="title"
                      xs12
                      sm6
                      md6
                    >
                      <v-text-field
                        v-model="title.ebayCode"
                        type="number"
                        oninput="validity.valid||(value='');"
                        :label="$t('ebayCode')"
                        color="red darken-1"
                        class="mt-3"
                      />
                    </v-flex>
                    <v-flex
                      v-if="title"
                      xs12
                      sm6
                      md6
                    >
                      <v-text-field
                        v-model="category"
                        outlined
                        :label="$t('ebayCategory')"
                        :hint="$t('ebayCategory')"
                        color="red darken-1"
                        class="mt-3"
                      />
                    </v-flex>

                    <v-flex xs12>
                      <v-layout
                        align-end
                        justify-end
                      >
                        <v-btn
                          :loading="loadingAssignPrice"
                          :disabled="!(title && price)"
                          color="red darken-1"
                          class="white--text"
                          @click="assignPrice"
                        >
                          {{ $t('leko_employees.ebay_shipping_price.assign_price') }}
                        </v-btn>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      sm6
                    >
                      <v-autocomplete
                        v-model="institution"
                        :items="institutions"
                        :loading="loadingInstitution"
                        :label="$t('parts.advanced_search.institution')"
                        :hint="$t('parts.advanced_search.institution')"
                        persistent-hint
                        item-text="title"
                        item-value="_id"
                        solo
                        color="red darken-1"
                        class="ml-2 mr-2 mt-3 pt-2"
                      />
                    </v-flex>

                    <v-flex
                      xs12
                      sm6
                      align-end
                      justify-end
                      class="ml-auto"
                      d-flex
                    >
                      <v-btn
                        :loading="loadingAssignInstitutionPrice"
                        :disabled="!(institution && title)"
                        color="red darken-1"
                        class="white--text"
                        @click="assignInstitutionPrice"
                      >
                        {{ $t('leko_employees.ebay_shipping_price.assign_institution_price') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>

              <v-flex
                xs12
                lg8
              >
                <v-container
                  v-if="title"
                >
                  <v-card class="pa-3 text-start">
                    <div class="mb-2">
                      <h3>
                        {{ $t('leko_employees.ebay_shipping_price.title_details') }}
                      </h3>
                    </div>
                    <div class="text-start mb-1 col-12 ">
                      <strong class="pt-1">partCode: </strong>
                      <a>{{ title.partCode }}</a>
                    </div>
                    <div class="text-start mb-1 col-12 ">
                      <strong class="col-7"> ebayCategory: </strong>
                      <a>{{ title.ebayCategory ? title.ebayCategory.it : '' }}</a>
                    </div>
                    <div class="text-start mb-1">
                      <strong> ebayCode: </strong>
                      <a>{{ title.ebayCode ? title.ebayCode : '' }}</a>
                    </div>
                    <div class="text-start mb-1">
                      <strong>  ebayShippingReferencePrice: </strong>
                      <a>{{ title.ebayShippingReferencePrice
                        ? title.ebayShippingReferencePrice : '' }}</a>
                    </div>
                  </v-card>
                </v-container>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <v-dialog
      v-model="loading"
      style="height: 100vh; width: 100vw;"
    >
      <v-card
        outlined
        color="transparent"
        class="col-12 justify-center pt-10 text-center"
        transparent
        style="height: 90vh; width: 95vw;"
      >
        <v-progress-circular
          color="#D32F2F"
          indeterminate
          size="100"
          style="margin-top: 35vh"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="success"
      max-width="300"
    >
      <v-alert
        v-model="success"
        type="success"
        dismissible
        :value="true"
      >
        {{ $t('institutions.form.success') }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="error"
      max-width="300"
    >
      <v-alert
        v-model="error"
        type="error"
        dismissible
        :value="true"
      >
        {{ $t('Error') }}
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import getRequest from '../api/getRequest';
import putRequest from '../api/putRequest';

export default {
  data: () => ({
    searchTitle: null,
    loadingTitle: false,
    title: null,
    titles: [],
    price: 0,
    loadingAssignPrice: false,
    institution: null,
    institutions: [],
    loadingInstitution: false,
    loadingAssignInstitutionPrice: false,
    loading: false,
    code: null,
    category: null,
    success: false,
    error: false,
  }),
  watch: {
    searchTitle(value) {
      if (value) {
        this.loadingTitle = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titles = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingTitle = false; });
      } else {
        this.title = null;
        this.titles = [];
      }
    },
    title: {
      handler(newTitle) {
        if (newTitle && newTitle.ebayCategory && newTitle.ebayCategory.it) {
          this.category = newTitle.ebayCategory.it;
        }
        if (newTitle && newTitle.ebayShippingReferencePrice) {
          this.price = newTitle.ebayShippingReferencePrice;
        }
      },
      deep: true,
    },
  },
  created() {
    this.getInstitutions();
  },
  methods: {
    assignPrice() {
      this.loadingAssignPrice = true;
      this.loading = true;
      putRequest('/ebay-shipping-price/global-price', '', {
        partCode: this.title.partCode, shippingPrice: this.price, ebayCode: this.title.ebayCode, ebayCategory: this.category,
      })
        .then((response) => {
          console.log(response);
          this.success = true;
          setTimeout(() => { this.success = false; }, 3000);
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          setTimeout(() => { this.error = false; }, 3000);
        })
        .finally(() => {
          this.loadingAssignPrice = false;
          this.loading = false;
        });
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/').then((response) => {
        console.log(response);
        this.institutions = response.institutions;
        this.loadingInstitution = false;
      }).catch((error) => {
        console.log(error);
        this.loadingInstitution = false;
      });
    },
    assignInstitutionPrice() {
      this.loadingAssignInstitutionPrice = true;
      this.loading = true;
      console.log(this.institution);
      putRequest('/ebay-shipping-price/institution-price', '', {
        partCode: this.title.partCode,
        shippingPrice: this.price,
        institutionId: this.institution,
      })
        .then((response) => {
          console.log(response);
          this.success = true;
          setTimeout(() => { this.success = false; }, 3000);
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          setTimeout(() => { this.error = false; }, 3000);
        })
        .finally(() => {
          this.loadingAssignInstitutionPrice = false;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.text-start{
  text-align: start !important;
}
</style>
