<template>
  <div>
    <v-layout
      row
      wrap
      pa-2
    >
      <!--USER-->
      <!--
      <v-flex
        xs12
        md4
        lg3
        pa-1
      >
        <v-card color="light-blue lighten-1">
          <v-card-text v-if="generalDetails">
            <v-layout column>
              <v-flex mt-1>
                <span class="title">
                  {{ generalDetails.user.name }} {{ generalDetails.user.surname }}
                </span>
              </v-flex>
              <v-flex
                mt-4
                style="margin-bottom: 2px;"
              >
                <span class="subheading">
                  {{ generalDetails.institution }}
                </span>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            --LOADER--
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="light-blue darken-4"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      -->

      <!--CARS-->
      <v-flex
        xs12
        sm6
        md3
        pa-1
      >
        <v-card
          v-if="generalDetails.cars !== null"
          color="cyan darken-1"
        >
          <v-card-text>
            <v-layout
              wrap
              align-center
            >
              <v-flex grow>
                <v-layout column>
                  <v-flex class="display-1 font-weight-bold white--text">
                    {{ generalDetails.cars }}
                  </v-flex>
                  <v-flex class="subheading white--text">
                    {{ $t('history.inserted_vehicles') }}
                  </v-flex>
                  <v-flex> &nbsp; </v-flex> <!-- PLACEHOLDER -->
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-icon size="50">
                  directions_car
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions
            class="justify-center"
            style="background-color: rgba(0, 0, 0, .12)"
          >
            <v-btn
              block
              flat
              small
              dark
              @click="$router.push({ path: '/vehicle-entry' })"
            >
              {{ $t('history.go_to_cars') }}
              <v-icon>
                arrow_right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          color="cyan darken-1"
        >
          <v-card-text>
            <!--LOADER-->
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="cyan darken-4"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!--PRICE-->
      <v-flex
        xs12
        sm6
        md3
        pa-1
      >
        <v-card
          v-if="generalDetails.price !== null"
          color="amber darken-1"
        >
          <v-card-text>
            <v-layout
              wrap
              align-center
            >
              <v-flex grow>
                <v-layout column>
                  <v-flex class="display-1 font-weight-bold white--text">
                    {{ generalDetails.price | priceFilter }}
                  </v-flex>
                  <v-flex class="subheading white--text">
                    {{ $t('history.inventory_price') }}
                  </v-flex>
                  <v-flex
                    class="white--text pointer"
                    @click="$router.push('/inventory?price=1');"
                  >
                    <span class="blueText underline">
                      {{ generalDetails.partsWithPrice1 }}
                      {{ $t('history.parts_with_price_1') }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-icon size="50">
                  euro
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions
            class="justify-center"
            style="background-color: rgba(0, 0, 0, .12)"
          >
            <v-btn
              block
              flat
              small
              dark
              @click="$router.push({ path: '/inventory' })"
            >
              {{ $t('history.go_to_inventory') }}
              <v-icon>
                arrow_right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          color="amber darken-1"
        >
          <v-card-text>
            <!--LOADER-->
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="amber darken-4"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!--PARTS-->
      <v-flex
        xs12
        sm6
        md3
        pa-1
      >
        <v-card
          v-if="generalDetails.parts !== null"
          color="red darken-1"
        >
          <v-card-text>
            <v-layout
              wrap
              align-center
            >
              <v-flex grow>
                <v-layout column>
                  <v-flex class="display-1 font-weight-bold white--text">
                    {{ generalDetails.parts }}
                  </v-flex>
                  <v-flex class="subheading white--text">
                    {{ $t('history.inventory_parts') }}
                  </v-flex>
                  <v-flex> &nbsp; </v-flex> <!-- PLACEHOLDER -->
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-icon size="50">
                  view_in_ar
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions
            class="justify-center"
            style="background-color: rgba(0, 0, 0, .12)"
          >
            <v-btn
              block
              flat
              small
              dark
              @click="$router.push({ path: '/inventory' })"
            >
              {{ $t('history.go_to_inventory') }}
              <v-icon>
                arrow_right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          color="red darken-1"
        >
          <v-card-text>
            <!--LOADER-->
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="red darken-4"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!--SALES-->
      <v-flex
        xs12
        sm6
        md3
        pa-1
      >
        <v-card
          v-if="generalDetails.sales !== null"
          color="green darken-1"
        >
          <v-card-text>
            <v-layout
              wrap
              align-center
            >
              <v-flex grow>
                <v-layout column>
                  <v-flex class="display-1 font-weight-bold white--text">
                    {{ generalDetails.sales }}
                  </v-flex>
                  <v-flex class="subheading white--text">
                    {{ $t('history.sales') }}
                  </v-flex>
                  <v-flex> &nbsp; </v-flex> <!-- PLACEHOLDER -->
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-icon size="50">
                  shopping_basket
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions
            class="justify-center"
            style="background-color: rgba(0, 0, 0, .12)"
          >
            <v-btn
              block
              flat
              small
              dark
              @click="$router.push({ path: '/sales' })"
            >
              {{ $t('history.go_to_sales') }}
              <v-icon>
                arrow_right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          color="green darken-1"
        >
          <v-card-text>
            <!--LOADER-->
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="green darken-4"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!--PARTS WITHOUT APPLICABILITY-->
      <v-flex
        v-if="$store.getters.role === 'LekoAdmin'"
        xs12
        sm6
        md3
        pa-1
      >
        <v-card
          v-if="generalDetails.partsWithoutAppl !== null"
          color="deep-orange darken-1"
        >
          <v-card-text>
            <v-layout
              wrap
              align-center
            >
              <v-flex grow>
                <v-layout column>
                  <v-flex class="display-1 font-weight-bold white--text">
                    {{ generalDetails.partsWithoutAppl }}
                  </v-flex>
                  <v-flex class="subheading white--text">
                    {{ $t('history.parts_without_applicability') }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-icon size="50">
                  help
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions
            class="justify-center"
            style="background-color: rgba(0, 0, 0, .12)"
          >
            <v-btn
              block
              flat
              small
              dark
              @click="$router.push({ path: '/inventory' })"
            >
              {{ $t('history.go_to_inventory') }}
              <v-icon>
                arrow_right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          color="deep-orange darken-1"
        >
          <v-card-text>
            <!--LOADER-->
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="deep-orange darken-4"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!--INSTITUTIONS-->
      <v-flex
        v-if="$store.getters.role === 'LekoAdmin'"
        xs12
        sm6
        md3
        pa-1
      >
        <v-card
          v-if="generalDetails.activeInstitutions !== null"
          color="light-green darken-1"
        >
          <v-card-text>
            <v-layout
              wrap
              align-center
            >
              <v-flex grow>
                <v-layout column>
                  <v-flex class="display-1 font-weight-bold white--text">
                    <v-layout
                      row
                      align-center
                    >
                      {{ generalDetails.activeInstitutions }}
                      <v-icon large>
                        check_circle
                      </v-icon>
                    </v-layout>
                  </v-flex>
                  <v-flex class="subheading white--text">
                    {{ $t('history.of') }}
                    {{ generalDetails.institutions }}
                    {{ $t('history.institutions') }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-icon size="50">
                  domain
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions
            class="justify-center"
            style="background-color: rgba(0, 0, 0, .12)"
          >
            <v-btn
              block
              flat
              small
              dark
              @click="$router.push({ path: '/institutions' })"
            >
              {{ $t('history.go_to_institutions') }}
              <v-icon>
                arrow_right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          color="light-green darken-1"
        >
          <v-card-text>
            <!--LOADER-->
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="light-green darken-4"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!--USERS-->
      <v-flex
        v-if="$store.getters.role === 'LekoAdmin'"
        xs12
        sm6
        md3
        pa-1
      >
        <v-card
          v-if="generalDetails.users"
          color="blue darken-1"
        >
          <v-card-text>
            <v-layout
              wrap
              align-center
            >
              <v-flex grow>
                <v-layout column>
                  <v-flex class="display-1 font-weight-bold white--text">
                    {{ generalDetails.users }}
                  </v-flex>
                  <v-flex class="subheading white--text">
                    {{ $t('history.users') }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-icon size="50">
                  people
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions
            class="justify-center"
            style="background-color: rgba(0, 0, 0, .12)"
          >
            <v-btn
              block
              flat
              small
              dark
              @click="$router.push({ path: '/users' })"
            >
              {{ $t('history.go_to_users') }}
              <v-icon>
                arrow_right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          color="blue darken-1"
        >
          <v-card-text>
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="blue darken-4"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!--PARTS AWAITING APPROVAL-->
      <v-flex
        v-if="$store.getters.role === 'LekoAdmin'"
        xs12
        sm6
        md3
        pa-1
      >
        <v-card
          v-if="generalDetails.tempPartsAwaitAppr !== null"
          color="orange darken-1"
        >
          <v-card-text>
            <v-layout
              wrap
              align-center
            >
              <v-flex grow>
                <v-layout column>
                  <v-flex class="display-1 font-weight-bold white--text">
                    {{ generalDetails.tempPartsAwaitAppr }}
                  </v-flex>
                  <v-flex class="subheading white--text">
                    {{ $t('history.parts_awaiting_approval') }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-icon size="50">
                  pending_actions
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions
            class="justify-center"
            style="background-color: rgba(0, 0, 0, .12)"
          >
            <v-btn
              block
              flat
              small
              dark
              @click="$router.push({ path: '/approve-parts' })"
            >
              {{ $t('history.go_to_approve_parts') }}
              <v-icon>
                arrow_right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          color="orange darken-1"
        >
          <v-card-text>
            <!--LOADER-->
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="orange darken-4"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!--ADMIN PANEL-->
      <v-flex
        v-if="$store.getters.role == 'InstitutionAdmin'"
        xs12
        pa-1
      >
        <v-card
          class="mx-auto"
          color="grey lighten-3"
        >
          <v-card-text>
            <v-layout
              row
              wrap
              align-start
            >
              <v-flex
                xs12
                md3
              >
                <v-layout
                  row
                  wrap
                  align-center
                  justify-center
                >
                  <v-flex
                    xs12
                    sm2
                  >
                    <v-text-field
                      v-model="priceChange.percentage"
                      type="number"
                      min="0"
                      label="%"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm10
                  >
                    <v-btn
                      depressed
                      :color="priceChange.type === 'decrease' ? 'primary' : ''"
                      style="margin-right: -8px"
                      @click="priceChange.type = 'decrease'"
                    >
                      <v-icon>keyboard_arrow_down</v-icon>
                    </v-btn>
                    <v-btn
                      depressed
                      :color="priceChange.type === 'increase' ? 'primary' : ''"
                      @click="priceChange.type = 'increase'"
                    >
                      <v-icon>keyboard_arrow_up</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn
                      color="red darken-2"
                      :disabled="!priceChange.percentage || priceChange.percentage < 1"
                      :loading="priceChange.loading"
                      @click="changeInventoryPrices"
                    >
                      {{ $t('change_inventory_prices') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <!--MAP-->
      <v-flex
        v-if="$store.getters.role == 'LekoAdmin'"
        xs12
      >
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1fuUZWVQP-mqrgu9boyVhmVajY6DeIRg&ehbc=2E312F"
          width="100%"
          height="500px"
        />
      </v-flex>

      <!--CHART-->
      <v-flex
        v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'InstitutionAdmin'"
        xs12
        pa-1
      >
        <v-card
          class="mx-auto"
          color="grey lighten-3"
        >
          <!-- CHART ACTIONS -->
          <v-card-actions>
            <v-layout
              row
              wrap
            >
              <v-flex :grow="$vuetify.breakpoint.smAndUp">
                <v-btn
                  :flat="chart.content !== 'sales'"
                  dark
                  color="green darken-1"
                  @click="selectChartContent('sales', '6.0')"
                >
                  {{ $t('history.sales') }}
                  <v-icon right>
                    shopping_basket
                  </v-icon>
                </v-btn>
                <v-btn
                  :flat="chart.content !== 'parts'"
                  dark
                  color="red darken-1"
                  @click="selectChartContent('parts', '2.0')"
                >
                  {{ $t('history.parts') }}
                  <v-icon right>
                    view_in_ar
                  </v-icon>
                </v-btn>
                <v-btn
                  :flat="chart.content !== 'ebay-leko'"
                  dark
                  color="green darken-1"
                  @click="selectChartContent('ebay-leko', '6.4')"
                >
                  {{ $t('history.ebay_sales') }}
                  <img
                    class="ml-3"
                    height="24"
                    src="@/assets/icons/ebay_colored.png"
                  >
                </v-btn>
                <v-btn
                  :flat="chart.content !== 'ebay-sales'"
                  dark
                  color="green darken-1"
                  @click="selectChartContent('ebay-sales', '6.2')"
                >
                  {{ $t('history.ebay_sales') }} Mult.
                  <!-- <img
                    class="ml-3"
                    height="24"
                    src="@/assets/icons/ebay_colored.png"
                  > -->
                </v-btn>
                
              </v-flex>
              <v-flex :shrink="$vuetify.breakpoint.smAndUp">
                <v-btn
                  depressed
                  :color="chart.type === 'line' ? 'primary' : ''"
                  style="margin-right: -8px"
                  @click="selectChartType('line')"
                >
                  {{ $t('history.line') }}
                </v-btn>
                <v-btn
                  depressed
                  :color="chart.type === 'bar' ? 'primary' : ''"
                  @click="selectChartType('bar')"
                >
                  {{ $t('history.bar') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>

          <v-divider />

          <!-- CHART CONTENT -->

          <v-card-text>
            <v-layout
              row
              wrap
              align-center
            >
              <v-flex
                xs12
                md4
              >
                <v-layout row>
                  <v-select
                    v-model="chart.timePeriod"
                    :items="timePeriodItems"
                    :label="$t('home.charts.time_period')"
                    :hint="$t('home.charts.select_time_period')"
                    solo
                    flat
                    item-text="title"
                    item-value="value"
                    persistent-hint
                    color="red darken-2"
                    background-color="grey lighten-4"
                  />
                  <v-btn
                    icon
                    @click="getPastUserActivity()"
                  >
                    <v-icon color="grey darken-2">
                      cached
                    </v-icon>
                  </v-btn>
                </v-layout>

                <v-layout justify-center>
                  <v-date-picker
                    v-model="chart.time"
                    :type="chart.timePeriod === 'Month' ? 'month' : 'date'"
                    color="red darken-2"
                    reactive
                  />
                </v-layout>
              </v-flex>
              <v-flex
                xs12
                md8
              >
                <v-layout justify-center>
                  <v-progress-circular
                    v-if="chart.loading"
                    :size="70"
                    color="red"
                    indeterminate
                  />
                </v-layout>
                <chart
                  v-if="!chart.loading"
                  :type="chart.type"
                  :data="chart.data"
                />
              </v-flex>
              <v-flex />
            </v-layout>
          </v-card-text>

          <v-card-text />
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import moment from 'moment';
import getRequest from '../../api/getRequest';

import Chart from '../chart.vue';

function generateRandomNumbers() {
  const arr = [];
  for (let a = 0; a < 3; a += 1) {
    arr.push(Math.floor(Math.random() * 256));
  }
  return arr;
}

export default {
  components: {
    chart: Chart,
  },
  filters: {
    priceFilter(value) {
      const str = value.toString();
      const result = str.split('.')[0].split('');
      if (result.length > 3) result.splice(result.length - 3, 0, ',');
      if (result.length > 7) result.splice(result.length - 7, 0, ',');
      return result.join('');
      // return value;
    },
  },
  data: () => ({
    chart: {
      loading: true,
      data: null,
      content: 'sales',
      status: '2.0',
      type: 'bar',
      timePeriod: 'Month',
      time: new Date().toISOString().substr(0, 7),
    },
    timePeriodItems: [
      { title: 'Month', value: 'Month' },
      { title: 'Week', value: 'Week' },
      { title: 'Day', value: 'Day' },
    ],
    generalDetails: {
      cars: null,
      price: null,
      parts: null,
      sales: null,
      partsWithoutAppl: null,
      activeInstitutions: null,
      institutions: null,
      tempPartsAwaitAppr: null,
      partsWithPrice1: null,
    },
    priceChange: {
      percentage: 0,
      type: 'increase',
      loading: false,
    },
  }),
  computed: {
    pastWeek() { return new Date(Date.now() - 604800000).toISOString().split('T')[0]; },
  },
  watch: {
    'chart.time': {
      handler(oldValue, newValue) {
        if (this.chart && this.chart.data && !this.chart.loading) {
          console.log('oldValue', oldValue);
          console.log('newValue', newValue);
          if (newValue) {
            this.getPastUserActivity();
          }
        }
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.getLocale();
  },
  created() {
    this.getGeneralDetails();
    this.selectChartContent('sales', '6.0');
    this.getPastUserActivity();
  },
  methods: {
    getLocale() {
      this.timePeriodItems[0].title = this.$i18n.t('home.charts.time_period_items.month');
      this.timePeriodItems[1].title = this.$i18n.t('home.charts.time_period_items.week');
      this.timePeriodItems[2].title = this.$i18n.t('home.charts.time_period_items.day');
    },
    getGeneralDetails() {
      getRequest(`/history/user/general/${this.$store.getters.userId}`)
        .then((response) => {
          this.generalDetails = response;
        })
        .catch(error => console.log(error));
    },
    getPastUserActivity() {
      this.chart.loading = true;
      getRequest(
        `/history/institution/single/${this.$store.getters.institutionId}/`,
        `recent=true&details=true&period=${this.chart.timePeriod}&time=${this.chart.time}&status=${this.chart.status}&role=${this.$store.getters.role}`,
      )
        .then((response) => {
          // LABELS
          const labels = [];
          response[Object.keys(response)[0]].forEach((element) => {
            if (this.chart.timePeriod === 'Day') {
              labels.push(`${moment(element.startingTime).format('LT')
              } - ${moment(element.endingTime).format('LT')}`);
            } else if (this.chart.timePeriod === 'Week') {
              labels.push(`${moment(element.startingTime).format('ddd, MMM D')}`);
            } else if (this.chart.timePeriod === 'Month') {
              labels.push(`${moment(element.startingTime).format('D')}`);
            }
          });
          // DATASETS
          const datasets = [];
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in response) {
            const rnd = generateRandomNumbers();
            datasets.push({
              label: key,
              data: response[key].map(element => element.value),
              backgroundColor: `rgba(${rnd[0]},${rnd[1]},${rnd[2]},${this.chart.type === 'line' ? '0.1' : '0.5'})`,
              borderColor: `rgba(${rnd[0]},${rnd[1]},${rnd[2]}, 1)`,
            });
          }
          this.chart.data = { labels, datasets };
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.chart.loading = false; });
    },
    selectChartContent(content, status) {
      this.chart.content = content;
      this.chart.status = status;
      this.getPastUserActivity();
    },
    selectChartType(type) {
      this.chart.type = type;
      this.getPastUserActivity();
    },
    changeInventoryPrices() {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const c = confirm(this.$i18n.t('confirm_alert'));
      if (c === true) {
        this.priceChange.loading = true;
        getRequest('/part/change-price', `institution_id=${this.$store.getters.institutionId}&amount=${this.priceChange.percentage}&type=${this.priceChange.type}`)
          .then(() => {})
          .catch(() => {})
          .finally(() => { this.priceChange.loading = false; });
      }
    },
  },
};
</script>
