<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-center
        justify-center
      >
        <!------------------------------------------TITLE------------------------------------------>
        <v-toolbar-title class="hidden-xs-only">
          {{ $t('sales.label') }}
        </v-toolbar-title>
        <!----------------------------------------------------------------------------------------->
        <v-divider
          class="mx-5 hidden-xs-only"
          inset
          vertical
        />
        <!-------------------------------------EXPORT BUTTONS-------------------------------------->
        <download-excel
          :fetch="exportData"
          type="xls"
          name="Sales"
          class="hidden-md-and-down"
        >
          <v-btn
            color="grey darken-4"
            dark
          >
            {{ $t('sales.export') }}
            <v-icon right>
              fas fa-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
        <!----------------------------------------------------------------------------------------->
        <v-spacer class="hidden-sm-and-down" />
        <!----------------------------------------------------------------------------------------->
        <!--SEARCH BY TITLE-->
        <v-autocomplete
          v-if="searchType === 'title'"
          v-model="titleCode"
          :loading="loadingTitle"
          :items="titles"
          :search-input.sync="searchTitle"
          :label="$t('sales.search')"
          :filter="() => true"
          :item-text="`title.${$store.getters.language}`"
          item-value="partCode"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          hide-no-data
          @keyup.enter="!advancedSearch ? searchMethod() : ''"
          @click:append="searchMethod()"
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
              class="listRowColor"
            >
              <v-flex>
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">Info Car</span>
                  <span
                    class="body-2"
                    v-html="highlight(item.title[$store.getters.language], searchTitle)"
                  />
                </v-layout>
              </v-flex>
              <v-flex
                v-for="(element, index) in item.related"
                :key="index"
                class="hidden-sm-and-down"
              >
                <v-layout
                  column
                  wrap
                >
                  <span
                    class="body-1"
                    style="margin-top: 17px;"
                    v-html="highlight(element.title[$store.getters.language], searchTitle)"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>
        <!--SERCH OTHER FIELDS-->
        <v-text-field
          v-else
          v-model="search"
          :label="$t('sales.search')"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          @keyup.enter="!advancedSearch ? searchMethod() : ''"
          @click:append="searchMethod()"
        />

        <v-spacer />

        <!--SEARCH TYPE MD-AND-UP-->
        <v-flex
          md2
          lg1
          class="hidden-sm-and-down"
        >
          <v-select
            v-model="searchType"
            :items="searchTypes"
            item-text="label"
            item-value="value"
            color="grey darken-4"
            solo
            flat
            hide-details
          />
        </v-flex>

        <!--ADVANCED MD-AND-UP-->
        <v-btn
          class=" mr-5 hidden-sm-and-down"
          dark
          small
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon left>
            {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('sales.advanced_search') }}
        </v-btn>
        <!----------------------------------------------------------------------------------------->
        <v-spacer class="hidden-sm-and-down" />
      </v-layout>
    </v-toolbar>

    <!--------------------------------------------------------------------------------------------->
    <v-layout
      align-center
      justify-center
      :class="{ 'pt-1 pb-2': $vuetify.breakpoint.smAndDown }"
      class="redColor"
    >
      <v-spacer />

      <!--SEARCH TYPE SM-AND-DOWN-->
      <v-flex
        xs4
        sm2
      >
        <v-select
          v-model="searchType"
          :items="searchTypes"
          item-text="label"
          item-value="value"
          color="grey darken-4"
          solo
          flat
          hide-details
          class="hidden-md-and-up"
        />
      </v-flex>


      <!--ADVANCED SM-AND-DOWN-->
      <v-btn
        class="hidden-md-and-up"
        dark
        small
        @click="advancedSearch = !advancedSearch"
      >
        <v-icon left>
          {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
        </v-icon>
        {{ $t('sales.advanced_search') }}
      </v-btn>

      <v-spacer />
    </v-layout>
    <!---------------------------------------ADVANCED SEARCH--------------------------------------->
    <v-layout
      v-if="advancedSearch"
      class="red darken-3 pt-2"
      row
      align-center
      justify-center
      wrap
    >
      <!-------------------------------------------BRAND------------------------------------------->
      <v-flex
        xs12
        sm6
        xl3
      >
        <v-autocomplete
          v-model="brand"
          :items="brands"
          :loading="loadingBrand"
          :label="$t('parts.advanced_search.brand')"
          item-text="title"
          flat
          solo
          color="red darken-1"
          class="ml-2 mr-2 mt-2"
          hide-details
          return-object
          :append-icon="brand === null ? 'arrow_drop_down' : 'keyboard_backspace'"
          @click:append="brand = null"
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
              align-center
              justify-center
            >
              <v-flex
                xs3
                sm2
                lg1
              >
                <v-img
                  :src="item.logo"
                  max-height="35"
                  max-width="35"
                />
              </v-flex>
              <v-flex
                xs9
                sm10
                lg11
              >
                <span> {{ item.title }} </span>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:selection="{ item }">
            <v-layout
              row
              wrap
              align-center
              justify-center
            >
              <v-flex
                xs3
                lg2
              >
                <v-img
                  :src="item.logo"
                  max-height="35"
                  max-width="35"
                />
              </v-flex>
              <v-flex
                xs9
                lg10
              >
                <span> {{ item.title }} </span>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>
      </v-flex>
      <!-------------------------------------------MODEL------------------------------------------->
      <v-flex
        xs12
        sm6
        xl3
      >
        <v-autocomplete
          v-model="model"
          :items="models"
          :loading="loadingModel"
          :label="$t('parts.advanced_search.model')"
          item-text="fullDescription"
          flat
          solo
          color="red darken-1"
          class="ml-2 mr-2 mt-2"
          hide-details
          return-object
          :append-icon="model === null ? 'arrow_drop_down' : 'keyboard_backspace'"
          @click:append="model = null"
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md7
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                  <span class="body-2">
                    {{ item.description }}
                  </span>
                </v-layout>
              </v-flex>

              <v-flex
                class="hidden-sm-and-down"
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:selection="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md7
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                  <span class="body-2">
                    {{ item.description }}
                  </span>
                </v-layout>
              </v-flex>

              <v-flex
                class="hidden-sm-and-down"
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>
      </v-flex>
      <!------------------------------------------VERSION------------------------------------------>
      <v-flex
        xs12
        xl6
      >
        <v-autocomplete
          v-model="version"
          :items="versions"
          :loading="loadingVersion"
          :label="$t('parts.advanced_search.version')"
          item-text="description"
          flat
          solo
          color="red darken-1"
          class="ml-2 mr-2 mt-2"
          hide-details
          return-object
          :append-icon="version === null ? 'arrow_drop_down' : 'keyboard_backspace'"
          @click:append="version = null"
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                  <span class="body-2">{{ item.description }}</span>
                </v-layout>
              </v-flex>
              <v-flex
                class="hidden-sm-and-down"
                md2
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                  <span class="body-2">{{ item.numberOfDoors }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                  <span class="body-2">
                    {{ item.power.kw }} kw,
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                  <span class="body-2">{{ item.engineCode }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                  <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>

          <template v-slot:selection="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                  <span class="body-2">{{ item.description }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                  <span class="body-2">{{ item.numberOfDoors }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                  <span class="body-2">
                    {{ item.power.kw }} kw,
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                  <span class="body-2">{{ item.engineCode }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                  <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>
      </v-flex>
      <!------------------------------------------STATUS------------------------------------------->
      <v-flex
        xs12
        lg6
        pr-2
      >
        <v-select
          v-model="advancedSearchItems.status"
          :items="statusItems"
          :label="$t('sales.status.label')"
          :hint="$t('sales.status.hint')"
          item-text="title"
          item-value="value"
          solo
          flat
          chips
          deletable-chips
          multiple
          color="black"
          prepend-icon="info"
          class="ml-2 mr-2 mt-2"
          persistent-hint
        />
      </v-flex>
      <!----------------------------------------START DATE----------------------------------------->
      <v-flex
        xs6
        :md4="$store.getters.role !== 'LekoAdmin' && $store.getters.role !== 'LekoEmployee'"
        :md6="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
        lg3
        pr-2
      >
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="advancedSearchItems.startDate"
              :label="$t('sales.starting_date')"
              :hint="$t('sales.select_starting_date')"
              solo
              flat
              color="black"
              persistent-hint
              prepend-icon="event"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="advancedSearchItems.startDate"
            color="red darken-3"
            @input="startDateMenu = false"
          />
        </v-menu>
      </v-flex>
      <!-----------------------------------------END DATE------------------------------------------>
      <v-flex
        xs6
        :md4="$store.getters.role !== 'LekoAdmin' && $store.getters.role !== 'LekoEmployee'"
        :md6="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
        lg3
        pr-2
      >
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="advancedSearchItems.endDate"
              :label="$t('sales.ending_date')"
              :hint="$t('sales.select_ending_date')"
              solo
              flat
              color="black"
              persistent-hint
              prepend-icon="event"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="advancedSearchItems.endDate"
            color="red darken-3"
            @input="endDateMenu = false"
          />
        </v-menu>
      </v-flex>
      <!---------------------------------------INSTITUTIONS---------------------------------------->
      <v-flex
        v-if="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
        xs12
        md6
        :lg4="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
        pr-2
      >
        <v-autocomplete
          v-model="advancedSearchItems.institution"
          :items="institutions"
          :loading="loadingInstitution"
          :label="$t('sales.institution')"
          :hint="$t('sales.select_institution')"
          item-text="title"
          item-value="_id"
          flat
          solo
          color="grey darken-4"
          prepend-icon="domain"
          persistent-hint
        />
      </v-flex>
      <!------------------------------------------CLIENT------------------------------------------->
      <v-flex
        xs12
        :md6="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
        :md4="$store.getters.role !== 'LekoAdmin' && $store.getters.role !== 'LekoEmployee'"
        :lg4="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
        :lg6="$store.getters.role !== 'LekoAdmin' && $store.getters.role !== 'LekoEmployee'"
        pr-2
      >
        <v-autocomplete
          v-model="advancedSearchItems.client"
          :items="clients"
          :loading="loadingClient"
          :label="$t('sales.client')"
          :hint="$t('sales.select_client')"
          item-text="name"
          item-value="_id"
          color="grey darken-4"
          solo
          flat
          prepend-icon="people"
          persistent-hint
        >
          <template
            slot="selection"
            slot-scope="data"
          >
            {{ data.item.name }} {{ data.item.surname }}
          </template>
          <template
            slot="item"
            slot-scope="data"
          >
            {{ data.item.name }} {{ data.item.surname }}
          </template>
        </v-autocomplete>
      </v-flex>
      <!-------------------------------------------PRICE------------------------------------------->
      <v-flex
        xs12
        :md8="$store.getters.role !== 'LekoAdmin' && $store.getters.role !== 'LekoEmployee'"
        :lg6="$store.getters.role !== 'LekoAdmin' && $store.getters.role !== 'LekoEmployee'"
        :lg4="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
      >
        <v-layout
          row
          align-center
          justify-center
          mb-4
        >
          <v-flex
            xs8
            sm10
            lg8
            pr-2
          >
            <v-range-slider
              v-model="advancedSearchItems.price"
              :label="$t('sales.price_range')"
              :max="50000"
              :min="0"
              :step="1000"
              color="grey darken-4"
            />
          </v-flex>

          <v-flex
            xs4
            sm2
            lg4
            pl-3
          >
            <span>{{ advancedSearchItems.price[0] }}-{{ advancedSearchItems.price[1] }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <!------------------------------------------------------------------------------------------->
      <v-flex xs12>
        <v-layout
          row
          align-center
          justify-center
        >
          <v-btn
            dark
            color="grey darken-4"
            @click="clearSearchFields"
          >
            {{ $t('parts.advanced_search.clear') }}
            <v-icon right>
              clear_all
            </v-icon>
          </v-btn>
          <v-btn
            dark
            color="grey darken-4"
            @click="searchMethod"
          >
            {{ $t('parts.advanced_search.search') }}
            <v-icon right>
              search
            </v-icon>
          </v-btn>
        </v-layout>
      </v-flex>
      <!------------------------------------------------------------------------------------------->
    </v-layout>
  </div>
</template>

<script>
import moment from 'moment';
import JsonExcel from 'vue-json-excel'; // eslint-disable-line import/extensions
import getRequest from '../../api/getRequest';

export default {
  components: {
    'download-excel': JsonExcel,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  props: ['items'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    searchTypes: [
      { label: 'Title', value: 'title' },
      { label: 'ID', value: 'id' },
      { label: 'Other', value: 'other' },
    ],
    searchType: 'title',
    advancedSearch: false,
    search: '',
    searchTitle: null,
    titleCode: null,
    titles: [],
    brands: [],
    models: [],
    versions: [],
    brand: null,
    model: null,
    version: null,
    statusItems: [
      { title: 'Sold-Online', value: 1.0 },
      { title: 'Sold-Desk', value: 1.1 },
      { title: 'Sold-Ebay', value: 1.2 },
      { title: 'Returned', value: 2.0 },
      { title: 'Returned-Inventory', value: 2.1 },
      { title: 'Returned-Damaged', value: 2.2 },
      { title: 'Sold-Ebay-Leko', value: 2.3 },
    ],
    startDateMenu: false,
    endDateMenu: false,
    institutions: [],
    clients: [],

    advancedSearchItems: {
      brand: null,
      model: null,
      version: null,
      status: [1.0, 1.1, 1.2, 2.0, 2.1, 2.2, 2.3],
      startDate: '',
      endDate: '',
      institution: null,
      client: null,
      price: [0, 50000],
    },
    loadingTitle: false,
    loadingBrand: false,
    loadingModel: false,
    loadingVersion: false,
    loadingInstitution: false,
    loadingClient: false,
  }),
  watch: {
    search(value) { if (!value && this.advancedSearch === false) this.$emit('clear-search'); },
    searchTitle(value, oldValue) {
      if (value && oldValue && value.length > oldValue.length) {
        this.loadingTitle = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titles = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingTitle = false; });
      } else if (!value) {
        if (this.advancedSearch === false) this.$emit('clear-search');
        this.titleCode = null;
      }
    },
    advancedSearch(value) {
      if (value) {
        this.getBrands();
        this.getClients();
        if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'LekoEmployee') {
          this.getInstitutions();
        }
      } else {
        this.advancedSearchItems = {
          brand: null,
          model: null,
          version: null,
          status: [1.0, 1.1, 1.2, 2.0, 2.1, 2.2, 2.3],
          startDate: '',
          endDate: '',
          institution: null,
          client: null,
          price: [0, 50000],
        };
      }
    },
    'advancedSearchItems.institution': {
      handler(value) {
        if (value) {
          this.getClientsByInst(value);
        } else if (this.advancedSearch) {
          this.getClients();
        }
      },
    },
    brand(value) {
      if (value) {
        this.models = [];
        this.model = null;
        this.versions = [];
        this.version = null;
        this.getModels(value.brandCode);
        this.advancedSearchItems.brand = value.brandCode;
      } else { this.advancedSearchItems.brand = null; }
    },
    model(value) {
      if (value) {
        this.versions = [];
        this.version = null;
        this.getVersions(value.brandCode, value.modelCode);
        this.advancedSearchItems.model = value.modelCode;
      } else { this.advancedSearchItems.model = null; }
    },
    version(value) {
      if (value) this.advancedSearchItems.version = value.versionCode;
      else this.advancedSearchItems.version = null;
    },
  },
  created() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.searchTypes[0].label = this.$i18n.t('sales.title');
      this.searchTypes[1].label = this.$i18n.t('sales.id');
      this.searchTypes[2].label = this.$i18n.t('sales.other');
      this.statusItems[0].title = this.$i18n.t('sales.status.sold_online');
      this.statusItems[1].title = this.$i18n.t('sales.status.sold_desk');
      this.statusItems[2].title = this.$i18n.t('sales.status.sold_ebay');
      this.statusItems[3].title = this.$i18n.t('sales.status.returned');
      this.statusItems[4].title = this.$i18n.t('sales.status.returned_inventory');
      this.statusItems[5].title = this.$i18n.t('sales.status.returned_damaged');
      this.statusItems[6].title = this.$i18n.t('sales.status.sold_ebay_leko');

    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B': return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D': return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G': return this.$i18n.t('parts.advanced_search.supply.gas');
        default: return supply;
      }
    },
    searchMethod() {
      if (this.advancedSearch) { // ADVANCED
        this.$emit('advanced-search', {
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
          advancedSearchItems: this.advancedSearchItems,
        });
      } else if (this.search.length > 0 || this.titleCode) { // REGULAR
        this.$emit('search', {
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
        });
      }
    },
    clearSearchFields() {
      this.search = '';
      this.titleCode = null;
      this.advancedSearchItems = {
        brand: null,
        model: null,
        version: null,
        status: [1.0, 1.1, 1.2, 2.0, 2.1, 2.2, 2.3],
        startDate: '',
        endDate: '',
        institution: null,
        client: null,
        price: [0, 50000],
      };
      this.brand = null;
      this.model = null;
      this.version = null;
      this.$emit('clear-search');
    },
    getClients() {
      this.loadingClient = true;
      getRequest('/client/', `role=${this.$store.getters.role}&institution_id=${this.$store.getters.institutionId}`)
        .then((response) => {
          console.log(response);
          this.clients = response.clients;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingClient = false; });
    },
    getClientsByInst(id) {
      this.loadingClient = true;
      getRequest('/client/institution', `role=${this.$store.getters.role}&institution_id=${id}`)
        .then((response) => {
          console.log(response);
          this.clients = response.clients;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingClient = false; });
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/')
        .then((response) => {
          console.log(response);
          this.institutions = response.institutions;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingInstitution = false; });
    },

    getBrands() {
      this.loadingBrand = true;
      getRequest('/info-car/brands')
        .then((response) => { this.brands = response.brands; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingBrand = false; });
    },
    getModels(brandCode) {
      this.loadingModel = true;
      getRequest('/info-car/models', `brand_code=${brandCode}`)
        .then((response) => { this.models = response.models; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingModel = false; });
    },
    getVersions(brandCode, modelCode) {
      this.loadingVersion = true;
      getRequest('/info-car/versions', `brand_code=${brandCode}&model_code=${modelCode}`)
        .then((response) => { this.versions = response.versions; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingVersion = false; });
    },
    highlight(title, search) {
      let result = title || '';
      if (search) {
        const index = title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase());
        // mark the text.
        if (index > -1) result = [title.slice(0, index), '<mark>', title.slice(index, index + search.length), '</mark>', title.slice(index + search.length)].join('');
      }
      return `<span>${result}</span>`;
    },
    setId(id) {
      this.searchType = 'id';
      this.search = id;
    },
    exportData() {
      const items = [];
      this.$props.items.forEach((item) => {
        const itemObject = {
          Status: item.status,
          'Part ID': item.part.old_id,
          Title: item.part.title,
          Price: `${item.part.price} ${item.part.currency}`,
          'Date Created': moment(item.date_created).format('DD-MM-YYYY LT'),
          Warranty: item.warranty.title,
          'Return Date': moment(item.return_date).format('DD-MM-YYYY'),
          Client: `${item.client.name} ${item.client.surname}`,
          Debt: item.debt,
          Notes: item.notes,
        };
        items.push(itemObject);
      });
      return items;
    },
    scanQr() {},
  },
};
</script>
