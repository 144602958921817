<template>
  <div>
    <v-dialog
      v-model="loading"
      style="height: 100vh; width: 100vw;"
    >
      <v-card
        outlined
        color="transparent"
        class="col-12 justify-center pt-10 text-center"
        transparent
        style="height: 90vh; width: 95vw;"
      >
        <v-progress-circular
          color="#D32F2F"
          indeterminate
          size="100"
          style="margin-top: 35vh"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="alert.value"
      max-width="500"
    >
      <v-alert
        v-model="alert.value"
        :type="alert.type"
      >
        {{ $t(alert.message) }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="addGoogleModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <strong> {{ $t('parts.inventory.want_to_add_google') }}</strong>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="acceptAddGoogle()"
          >
            {{ $t('parts.inventory.yes') }}
          </v-btn>
          <v-btn
            small
            @click="cancelAddGoogle()"
          >
            {{ $t('parts.inventory.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteGoogleModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <strong> {{ $t('parts.inventory.want_to_delete_google') }}</strong>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="acceptDeleteGoogle()"
          >
            {{ $t('parts.inventory.yes') }}
          </v-btn>
          <v-btn
            small
            @click="cancelDeleteGoogle()"
          >
            {{ $t('parts.inventory.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successAddGoogleModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <v-alert
          :value="true"
          type="success"
        >
          <strong> {{ $t('parts.inventory.success_google') }}</strong>
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="successAddGoogle()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="errorGoogleModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <v-alert
          :value="true"
          type="error"
        >
          <strong> {{ errorMsg ? errorMsg : $t('parts.inventory.error_google') }}</strong>
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="errorGoogle()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="alreadyOnGoogleModal"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in onGoogle"
          :key="index"
          :value="true"
          type="warning"
        >
          <strong>
            {{ item.infoCar && item.infoCar.description && item.infoCar.description.descriptionId && item.infoCar.description.descriptionId.title ? item.infoCar.description.descriptionId.title.it : '' }}
          </strong>
          {{ $t('parts.inventory.already_on_google') }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="closeAlreadyGoogle()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="googleMultipleDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in googleResponse"
          :key="index"
          :value="true"
          :type="item.errorMsg ? 'error' : 'success'"
        >
          <strong>
            {{ item.lekoItem }}
          </strong>
          {{ item.errorMsg ? item.errorMsg : $t('parts.inventory.success_google') }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="closeGoogleMultiple()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="googleRemoveMultipleDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in googleResponse"
          :key="index"
          :value="true"
          :type="item.errorMsg ? 'error' : 'success'"
        >
          <strong>
            {{ item.lekoItem }}
          </strong>
          {{ item.errorMsg ? item.errorMsg : $t('parts.inventory.success_deleted_google') }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="googleRemoveMultipleDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="acceptAddMultipleGoogleDialog"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <strong> {{ $t('parts.inventory.want_to_add_multiple_google') }}</strong>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="acceptAddMultipleGoogle()"
          >
            {{ $t('parts.inventory.yes') }}
          </v-btn>
          <v-btn
            small
            @click="cancelGoogleMultiple()"
          >
            {{ $t('parts.inventory.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import getRequest from '../../api/getRequest';


export default {
  components: {
  },
  data: () => ({
    carData: null,
    xmlData: null,
    successAddGoogleModal: false,
    errorGoogleModal: false,
    addGoogleModal: false,
    deleteGoogleModal: false,
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    alreadyOnGoogleModal: false,
    onGoogle: [],
    googleResponse: null,
    googleMultipleDialog: false,
    loading: false,
    multipleItems: [],
    acceptAddMultipleGoogleDialog: false,
    googleRemoveMultipleDialog: false,
    errorMsg: null,
    // baseUrl: 'http://localhost:3000',
    baseUrl: 'https://api.lekotech.com',
    googleItem: null,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    errorGoogle() {
      this.errorGoogleModal = false;
    },
    successAddGoogle() {
      this.successAddGoogleModal = false;
      this.close();
    },
    acceptDeleteGoogle() {
      // TBD
      this.loading = true;
      axios.post(`${this.baseUrl}/google-merchant/delete-item/${this.googleItem._id}`, { institutionId: this.$store.getters.institutionId }, {
        headers: { authorization: localStorage.token },
      }).then((res) => {
        if (res) {
          this.loading = false;
          console.log('resss', res);
          this.deleteGoogleModal = false;
          this.alert = {
            value: true,
            type: 'success',
            message: 'parts.inventory.successfully_removed_google',
          };
          setTimeout(() => { this.alert.value = false; }, 3000);
          this.carData = null;
          this.googleItem = null;
        }
      }).catch((err) => {
        console.log('google error', err.response.data);
        this.loading = false;
        this.deleteGoogleModal = false;
        if (err && err.response && err.response.data && err.response.data.errorMsg) {
          this.errorMsg = err.response.data.errorMsg;
          this.errorGoogleModal = true;
        }
      })
        .finally(() => {
          this.deleteGoogleModal = false;
          this.loading = false;
          this.$emit('close');
        });
    },
    cancelDeleteGoogle() {
      this.deleteGoogleModal = false;
    },
    acceptAddGoogle(item) {
      // TBD
      let itemToSend = null;
      if (item) {
        itemToSend = item;
      } else {
        itemToSend = this.googleItem;
      }
      this.addGoogleModal = false;
      this.loading = true;
      axios.post(
        `${this.baseUrl}/google-merchant/add-item/${itemToSend._id}`, { institutionId: this.$store.getters.institutionId },
        { headers: { authorization: localStorage.token } },
      )
        .then((res) => {
          if (res) {
            this.loading = false;
            console.log('resss', res);
            this.successAddGoogleModal = true;
          }
        }).catch((err) => {
          this.loading = false;
          console.log('google error', err.response.data);
          if (err && err.response && err.response.data && err.response.data.errorMsg) {
            this.errorMsg = err.response.data.errorMsg;
            this.errorGoogleModal = true;
          }
        })
        .finally(() => {
          this.loading = false;
          this.$emit('close');
        });
    },
    cancelAddGoogle() {
      this.addGoogleModal = false;
    },
    async openAddGoogle(item) {
      // TBD
      this.xmlData = null;
      this.googleItem = item;
      if (item.car_id) {
        await this.getCarData(item.car_id);
      }
      console.log('inside add google dialog', item);

      this.addGoogleModal = true;
    },
    async getCarData(id) {
      await getRequest('/car/', `id=${id}`)
        .then((response) => { this.carData = response.car; })
        .catch((error) => { console.log(error); });
    },
    openDeleteGoogle(item) {
      this.googleItem = item;
      this.deleteGoogleModal = true;
    },
    addMultipleGoogleItems(items) {
      console.log('items selected to add', items.length);
      items.forEach((item) => {
        console.log('googlestatus', item.googleMerchantStatus);
        if (item.googleMerchantStatus === true) {
          this.onGoogle.push(item);
        }
      });
      if (this.onGoogle.length > 0) {
        this.alreadyOnGoogleModal = true;
      } else {
        this.multipleItems = items;
        this.acceptAddMultipleGoogleDialog = true;
      }
    },
    deleteMultipleGoogleItems(items) {
      // TBD
      const itemIds = items.map(item => item._id);

      this.loading = true;
      axios.post(`${this.baseUrl}/google-merchant/delete-items`, { ids: itemIds, institutionId: this.$store.getters.institutionId }, {
        headers: { authorization: localStorage.token },
      })
        .then((response) => {
          console.log('Items successfully deleted from google:', response.data);
          this.loading = false;
          this.googleResponse = response.data;
          this.googleRemoveMultipleDialog = true;
          this.multipleItems = [];
        })
        .catch((error) => {
          console.error('Error deleting items from google:', error);
          this.loading = false;
          this.multipleItems = [];
          this.alert = {
            value: true,
            type: 'error',
            message: 'parts.inventory.error_deleting_items',
          };
          setTimeout(() => { this.alert.value = false; }, 5000);
        })
        .finally(() => {
          this.loading = false;
          this.multipleItems = [];
          this.$emit('close');
        });
      console.log('items selected to delete', items.length);
    },
    acceptAddMultipleGoogle() {
    //  TBD
      const items = this.multipleItems;
      console.log('items', items);
      this.acceptAddMultipleGoogleDialog = false;
      //  Create an array of item IDs
      const itemIds = items.map(item => item._id);
      console.log('itemIds', itemIds);
      this.loading = true;
      axios.post(`${this.baseUrl}/google-merchant/add-items`, { ids: itemIds, institutionId: this.$store.getters.institutionId }, {
        headers: { authorization: localStorage.token },
      })
        .then((response) => {
          console.log('Items successfully added to google:', response.data);
          this.loading = false;
          this.googleResponse = response.data;
          this.googleMultipleDialog = true;
          this.multipleItems = [];
        })
        .catch((error) => {
          console.error('Error adding items to google:', error);
          this.loading = false;
          this.multipleItems = [];
          this.alert = {
            value: true,
            type: 'error',
            message: 'parts.inventory.error_adding_items',
          };
          setTimeout(() => { this.alert.value = false; }, 5000);
        })
        .finally(() => {
          this.loading = false;
          this.multipleItems = [];
          this.$emit('close');
        });
    },
    cancelGoogleMultiple() {
      this.acceptAddMultipleGoogleDialog = false;
      this.googleResponse = null;
      this.multipleItems = [];
    },
    closeAlreadyGoogle() {
      this.alreadyOnGoogleModal = false;
      this.onGoogle = [];
    },
    closeGoogleMultiple() {
      this.googleMultipleDialog = false;
      this.googleResponse = null;
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
    showActionsErrorModal(msg) {
      this.errorGoogleModal = true;
      this.errorMsg = msg;
    },
  },
};
</script>
