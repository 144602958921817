<template>
  <div>
    <v-data-table
      :pagination.sync="pagination"
      :headers="headers"
      :items="sales"
      :total-items="totalItems"
      :rows-per-page-items="[5,10,25]"
      :loading="loading"
      class="elevation-1"
      @update:pagination="updatePagination"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <tr
          :class="{
            activeSaleRow: props.item.return_date >= currentDate,
            finishedSaleRow: props.item.return_date < currentDate,
            tableRowColorSelected : props.index === activeRow,
          }"
          @click="activeRow = props.index"
        >
          <!------------------------------------------ID------------------------------------------->
          <!--OLD ID-->
          <td
            v-if="props.item.part
              && props.item.part.old_id
              && props.item.part.old_id !== undefined"
          >
            <v-layout
              class="pointer"
              ma-1
              align-center
              justify-center
              column
              @click="openQrDialog({
                id: props.item.part.old_id,
                type: 'inventory',
                printed: props.item.part.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item.part.old_id"
              />
              {{ props.item.part.old_id
                .slice(props.item.part.old_id.length-6, props.item.part.old_id.length) }}
            </v-layout>
          </td>
          <!--NEW ID-->
          <td
            v-else
            class="text-xs"
          >
            <v-layout
              class="pointer"
              ma-1
              align-center
              justify-center
              column
              @click="openQrDialog({
                id: props.item.part._id,
                type: 'inventory',
                printed: props.item.part.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item.part._id"
              />
              {{ props.item.part._id
                .slice(props.item.part._id.length-6, props.item.part._id.length) }}
            </v-layout>
          </td>
          <!----------------------------------------ACTIONS---------------------------------------->
          <td>
            <v-layout
              column
              align-center
            >
              <v-btn
                :disabled="!props.item.part_id || props.item.type === 'Reservation'"
                icon
                @click="details(props.item.part_id)"
              >
                <v-icon color="grey darken-2">
                  view_list
                </v-icon>
              </v-btn>
            </v-layout>
            <v-layout
              column
              align-center
            >
              <v-btn
                :disabled="!props.item.part_id || props.item.type === 'Reservation'"
                icon
                @click="print(props.item)"
              >
                <v-icon color="grey darken-2">
                  print
                </v-icon>
              </v-btn>
            </v-layout>
            <v-layout
              column
              align-center
            >
              <v-btn
                v-if="props.item.status === 'Sold' || props.item.status === 'SoldCustomShop'"
                :disabled="(props.item.part_id
                  && props.item.part_id.order
                  && props.item.part_id.order.value === true) || props.item.type === 'Reservation'"
                icon
                @click="returnE(props.item)"
              >
                <v-icon color="grey darken-2">
                  compare_arrows
                </v-icon>
              </v-btn>
            </v-layout>
          </td>

          <!-----------------------------------------TITLE----------------------------------------->
          <td class="text-xs">
            <v-layout v-if="props.item.type === 'Reservation'">
              <span class="text-xs font-weight-bold">
                {{ props.item.reservation_part.description }}
              </span>
            </v-layout>
            <v-layout
              v-else
              column
            >
              <v-flex
                v-if="props.item.part_id
                  && props.item.part_id.infoCar
                  && props.item.part_id.infoCar.description"
              >
                <span
                  v-if="props.item.part_id.infoCar.description.descriptionId
                    && props.item.part_id.infoCar.description.descriptionId.title"
                  class="text-xs font-weight-bold"
                >
                  <!-- eslint-disable-next-line max-len -->
                  {{ props.item.part_id.infoCar.description.descriptionId.title[$store.getters.language] }}
                </span>
                <span
                  v-if="props.item.part_id.infoCar.description.positionCode"
                  class="text-xs"
                >
                  {{ getPosition(props.item.part_id.infoCar.description.positionCode) }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------MANUFACTURER CODE----------------------------------->
          <td>
            <span v-if="props.item.part_id">
              {{ props.item.part_id.manufacturer_code }}
            </span>
            <span v-else />
          </td>
          <!--------------------------------------PRA NUMBER--------------------------------------->
          <td>
            <span v-if="props.item.part_id">
              {{ props.item.part_id.pra }}
            </span>
            <span v-else />
          </td>
          <!--------------------------------------INSTITUTION-------------------------------------->
          <td v-if="$store.getters.role === 'LekoAdmin'">
            <span
              v-if="$store.getters.role === 'LekoAdmin'
                && props.item.institution_id
                && props.item.institution_id.title"
            >
              {{ props.item.institution_id.title }}
            </span>
            <span
              v-else-if="$store.getters.role === 'LekoAdmin'
                && (!props.item.institution_id || !props.item.institution_id.title)"
            />
          </td>
          <!----------------------------------------IMAGES----------------------------------------->
          <td>
            <v-layout
              align-center
              justify-center
            >
              <img
                v-if="props.item.part_id && props.item.part_id._id && props.item.part_id.images"
                :src="`${server}/images?q=${
                  props.item.part_id.images && props.item.part_id.images.length
                    ? props.item.part_id.images[0].thumb : ''
                }`"
                class="ma-1 pointer"
                height="75"
                width="100"
                alt="photo"
                @click="showImages(props.item.part_id._id,
                                   props.item.part_id.images,
                                   props.item.part_id.date_created)"
              >

              <img
                v-if="props.item.type === 'Reservation' && props.item.reservation_part_id
                  && props.item.reservation_part_id._id && props.item.reservation_part_id.images"
                :src="`${server}/images/reservation?q=${props.item.reservation_part_id._id
                }-${props.item.reservation_part_id.images[0]}`"
                class="ma-1 pointer"
                height="75"
                width="100"
                @click="showResImages(props.item.reservation_part_id._id,
                                      props.item.reservation_part_id.images)"
              >
            </v-layout>
          </td>
          <!--------------------------------------PART NOTES--------------------------------------->
          <td>
            <span v-if="props.item.type === 'Reservation' && props.item.reservation_part.notes">
              {{ props.item.reservation_part.notes }}
            </span>
            <span v-else-if="props.item.part_id">
              {{ props.item.part_id.notes }}
            </span>
            <span v-else />
          </td>
          <!--------------------------------------SALE NOTES--------------------------------------->
          <td class="text-xs">
            <v-layout
              row
              align-center
              justify-center
            >
              <!--Notes-->
              <v-flex>
                <div v-if="props.item.notes"> {{ props.item.notes }} </div>
                <div v-else />
              </v-flex>
              <!--Edit-->
              <v-flex>
                <v-edit-dialog 
                  large 
                  return-value.sync="props.item.notes" 
                  lazy 
                  @save="saveNotes(props.item._id, props.item.notes)"
                  @cancel="refreshSales"
                >
                  <v-btn icon>
                    <v-icon color="grey darken-2"> edit </v-icon>
                  </v-btn>

                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.notes"
                      color="red darken-1"
                    />
                  </template>
                </v-edit-dialog>
              </v-flex>
            </v-layout>
          </td>
          <!----------------------------------------CLIENT----------------------------------------->
          <td v-if="props.item.client && props.item.client.name && props.item.client.surname">
            {{ props.item.client.name }} {{ props.item.client.surname }}
          </td>
          <td v-else-if="props.item.customShopUser && props.item.customShopUser == 'lekoeBay'"> eBay</td>
          <td v-else></td>
          <!-----------------------------------------DEBT------------------------------------------>
          <td>
            <span v-if="props.item.debt">{{ difference(props.item.debt) }}</span> <span v-else />
          </td>
          <!-----------------------------------------PRICE----------------------------------------->
          <td>
            <span
              v-if="props.item.type === 'Reservation'
                && props.item.reservation_part.price && props.item.reservation_part.price"
            >
              {{ props.item.reservation_part.price }} {{ props.item.reservation_part.currency }}
            </span>
            <span v-if="props.item.part && props.item.part.price && props.item.part.currency">
              {{ props.item.part.price }} {{ props.item.part.currency }}
            </span>
            <span v-else />
          </td>
          <!---------------------------------------LOCATION---------------------------------------->
          <td
            v-if="props.item.part_id && props.item.part_id.shelf_data"
            class="text-xs"
          >
            <span>
              {{ props.item.part_id.shelf_data.shelf.room_name }} -
              {{ props.item.part_id.shelf_data.shelf.location_code }}:&nbsp;
              {{ props.item.part_id.shelf_data.shelf.x }},
              {{ props.item.part_id.shelf_data.shelf.y }}
            </span>

            <div class="text-no-wrap ">
              <span class="caption">Mag: </span>
              <span class="font-weight-bold caption">{{ props.item.part_id.shelf_data.repo }}</span>
            </div>
          </td>
          <td
            v-else
            class="text-xs"
          >
            -
          </td>
          <!----------------------------------------QUALITY---------------------------------------->
          <td v-if="props.item.part_id && props.item.part_id.quality">
            <div style="width: 75px">
              <v-rating
                :value="props.item.part_id.quality.rating"
                color="red"
                background-color="red darken-4"
                readonly
                dense
                size="12"
              />
            </div>
          </td>
          <td v-else />
          <!----------------------------------------WARRANTY--------------------------------------->
          <td v-if="props.item.warranty">
            {{ props.item.warranty.return_days / 86400 }} {{ $t('warranties.days') }}
          </td>
          <td v-else />
          <!-------------------------------------DATE CREATED-------------------------------------->
          <td>
            <span v-if="props.item.date_created">
              {{ props.item.date_created | moment }}
            </span>
            <span v-else />
          </td>
          <!--------------------------------------RETURN DATE-------------------------------------->
          <td v-if="props.item.return_date">
            {{ props.item.return_date | moment }}
          </td>
          <td v-else />
          <!---------------------------STATUS (TAKEN FROM THE SALES DOC)--------------------------->
          <!-- <td>
            <v-layout
              align-center
              justify-center
            >
              --SOLD--
              <v-btn
                v-if="props.item.status === 'Sold' && !props.item.part_id"
                small
                color="primary"
                outline
              >
                {{ $t('sales.table.sold_desk') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 'Sold'
                  && props.item.part_id
                  && props.item.part_id.order
                  && props.item.part_id.order.value === true"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('sales.table.sold_online') }}
              </v-btn>
              <v-btn
                v-else-if="props.item.status === 'Sold'
                  && props.item.part_id
                  && props.item.part_id.order
                  && props.item.part_id.order.value === false"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('sales.table.sold_desk') }}
              </v-btn>
              --RETURNED--
              <v-btn
                v-else-if="props.item.status === 'Returned-Inventory'"
                small
                color="error"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('sales.table.returned_inventory') }}
              </v-btn>
              <v-btn
                v-else-if="props.item.status === 'Returned-Damaged'"
                small
                color="error"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('sales.table.returned_damaged') }}
              </v-btn>
              <v-btn
                v-else-if="props.item.status === 'Returned'"
                small
                color="error"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('sales.table.returned') }}
              </v-btn>
            </v-layout>
          </td> -->
          <!--------------------------------------------------------------------------------------->
          <!---------------------------STATUS (TAKEN FROM THE PART DOC)---------------------------->
          <td>
            <v-layout
              align-center
              justify-center
            >
              <!--SOLD BUT THE PART IS MISSING-->
              <v-btn
                v-if="props.item.status === 'Sold' && !props.item.part_id"
                small
                color="primary"
                outline
              >
                {{ $t('sales.table.sold_desk') }}
              </v-btn>
              <v-btn
                v-else-if="props.item.status === 'SoldCustomShop' && !props.item.part_id"
                small
                color="primary"
                outline
              >
                {{ $t('sales.table.sold_ebay') }}
              </v-btn>
              <!--OTHER SALE STATUS AND THE PART IS MISSING ???-->

              <!--SOLD-->
              <v-btn
                v-if="props.item.part_id
                  && props.item.part_id.status === 5
                  && props.item.status
                  && props.item.status === 'SoldCustomShop'
                  && props.item.customShopUser
                  && props.item.customShopUser === 'multibreves'"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                <span v-if="props.item.customShopPlatformSold && props.item.customShopPlatformSold === 'Ovoko'">
                  {{ $t('sales.table.sold_ovoko') }}
                </span>
                <span v-else-if="props.item.customShopPlatformSold && props.item.customShopPlatformSold === 'eBay'">
                  {{ $t('sales.table.sold_ebay') }}
                </span>
                <!--Set the default platform as eBay (That's how it was before we specified it)-->
                <span v-else>
                  {{ $t('sales.table.sold_ebay') }}
                </span>
              </v-btn>
              <v-btn
                v-else-if="props.item.part_id
                  && props.item.part_id.status === 5
                  && props.item.status
                  && props.item.status === 'SoldCustomShop'
                  && props.item.customShopUser
                  && props.item.customShopUser === 'lekoeBay'"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('sales.table.sold_ebay_leko') }}
              </v-btn>
              <v-btn
                v-else-if="props.item.part_id
                  && props.item.part_id.status === 5
                  && props.item.status
                  && props.item.status === 'SoldCustomShop'"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('sales.table.sold_online') }}
              </v-btn>
              <v-btn
                v-else-if="props.item.part_id
                  && props.item.part_id.status === 5
                  && props.item.status
                  && props.item.status === 'Sold'"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('sales.table.sold_desk') }}
              </v-btn>

              <!--ENTERED (HIGHLY UNLIKELY TO EVER HAVE THIS STATUS)-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 1"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.entered') }}
              </v-btn>

              <!--RETURNED-SALES-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 2"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.returned') }}
              </v-btn>

              <!--RETURNED-SALES-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 2.1"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.returned_shop') }}
              </v-btn>

              <!--RE-ENTERED-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 3"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.re_entered') }}
              </v-btn>

              <!--RE-ENTERED-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 4"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.found') }}
              </v-btn>

              <!--LOST-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 6"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.lost') }}
              </v-btn>

              <!--LOST-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 6.1"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.lost_shop') }}
              </v-btn>

              <!--DAMAGED-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 7"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.damaged') }}
              </v-btn>

              <!--DAMAGED SALES-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 8"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.damaged_sales') }}
              </v-btn>

              <!--DAMAGED SHOP-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 8.1"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.damaged_shop') }}
              </v-btn>

              <!--DAMAGED LOST-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 9"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.damaged_shop') }}
              </v-btn>

              <!--DAMAGED LOST-->
              <v-btn
                v-if="props.item.part_id && props.item.part_id.status === 0"
                small
                color="primary"
                outline
                @click="movement(props.item.part_id)"
              >
                {{ $t('parts.status.recycled') }}
              </v-btn>
            </v-layout>
          </td>
          <!--------------------------------------------------------------------------------------->
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  props: ['search'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    loading: false,
    activeRow: null,
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'date_created',
      descending: true,
    },
    totalItems: 0,
    sales: [],
    headers: [
      {
        text: 'Part ID',
        sortable: false,
        value: 'part.old_id',
      },
      {
        text: 'Actions',
        sortable: false,
        value: '',
      },
      {
        text: 'Title',
        sortable: false,
        value: 'part.title',
      },
      {
        text: 'Manufacturer Code',
        sortable: false,
        value: 'part.manufacturer_code',
      },
      {
        text: 'PRA Number',
        sortable: false,
        value: 'part.pra',
      },
      {
        text: 'Images',
        sortable: false,
        value: '',
      },
      {
        text: 'Part Notes',
        sortable: false,
        value: '',
      },
      {
        text: 'Sale Notes',
        sortable: false,
        value: 'notes',
      },
      {
        text: 'Client',
        sortable: false,
        value: 'client.name',
      },
      {
        text: 'Debt',
        sortable: false,
        value: 'debt',
      },
      {
        text: 'Price',
        sortable: true,
        value: 'part.price',
      },
      {
        text: 'Location',
        sortable: false,
        value: '',
      },
      {
        text: 'Quality',
        sortable: false,
        value: '',
      },
      {
        text: 'Warranty',
        sortable: false,
        value: 'warranty.title',
      },
      {
        text: 'Date Created',
        sortable: true,
        value: 'date_created',
      },
      {
        text: 'Return Date',
        sortable: true,
        value: 'return_date',
      },
      {
        text: 'Status',
        sortable: true,
        value: 'status',
      },
    ],
  }),
  computed: {
    server() { return this.$serverUri; },
    searchObject() { return this.$props.search; },
    currentDate() { return new Date().toISOString(); },
  },
  watch: {
    pagination: {
      handler() {
        if (this.searchObject.type === null) {
          this.getSales();
        } else if (this.searchObject.type === 'regularSearch') {
          this.regularSearch(this.searchObject.data);
        } else if (this.searchObject.type === 'advancedSearch') {
          this.advancedSearch(this.searchObject.data);
        } else {
          console.log('search type is invalid');
        }
      },
      deep: true,
    },
    searchObject: {
      handler(value) {
        if (value.type === null) {
          this.getSales();
        } else if (value.type === 'regularSearch') {
          this.regularSearch(value.data);
        } else if (value.type === 'advancedSearch') {
          this.advancedSearch(value.data);
        } else {
          console.log('search type is invalid');
        }
      },
    },
    sales(value) { this.$emit('items', value); },
  },
  created() {
    /**
     * Add institution to table headers for LEKO Admin.
     */
    if (this.$store.getters.role === 'LekoAdmin') {
      this.headers.splice(4, 0, { text: 'Institution', sortable: false, value: 'institution_id' });
    }
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('sales.table.part_id');
      this.headers[1].text = this.$i18n.t('sales.table.actions');
      this.headers[2].text = this.$i18n.t('sales.table.title');
      this.headers[3].text = this.$i18n.t('sales.table.manufacturer_code');
      this.headers[4].text = this.$i18n.t('sales.table.pra');
      this.headers[5].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.institution')
        : this.$i18n.t('sales.table.images');
      this.headers[6].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.images')
        : this.$i18n.t('sales.table.part_notes');
      this.headers[7].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.part_notes')
        : this.$i18n.t('sales.table.sale_notes');
      this.headers[8].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.sale_notes')
        : this.$i18n.t('sales.table.client');
      this.headers[9].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.client')
        : this.$i18n.t('sales.table.debt');
      this.headers[10].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.debt')
        : this.$i18n.t('sales.table.price');
      this.headers[11].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.price')
        : this.$i18n.t('sales.table.location');
      this.headers[12].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.location')
        : this.$i18n.t('sales.table.quality');
      this.headers[13].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.quality')
        : this.$i18n.t('sales.table.warranty');
      this.headers[14].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.warranty')
        : this.$i18n.t('sales.table.date_created');
      this.headers[15].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.date_created')
        : this.$i18n.t('sales.table.return_date');
      this.headers[16].text = this.$store.getters.role === 'LekoAdmin'
        ? this.$i18n.t('sales.table.return_date')
        : this.$i18n.t('sales.table.status');
      if (this.$store.getters.role === 'LekoAdmin') {
        this.headers[17].text = this.$i18n.t('sales.table.status');
      }
    },
    getSales() {
      this.loading = true;
      getRequest(
        '/sale/datatable',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.totalItems = response.count;
          this.sales = response.sales;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    regularSearch(data) {
      this.loading = true;
      getRequest(
        '/sale/search/regular',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&search_field=${data.searchField
        }&title_code=${data.titleCode
        }&type=${data.type
        }&language=${this.$store.getters.language}`,
      )
        .then((response) => {
          console.log(response);
          this.totalItems = response.count;
          this.sales = response.sales;
        })
        .catch((error) => { console.log(error.response); })
        .finally(() => { this.loading = false; });
    },
    advancedSearch(data) {
      this.loading = true;
      getRequest(
        '/sale/search/advanced',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&search_field=${data.searchField
        }&language=${this.$store.getters.language
        }&title_code=${data.titleCode
        }&type=${data.type
        }&brand=${data.advancedSearchItems.brand
        }&model=${data.advancedSearchItems.model
        }&version=${data.advancedSearchItems.version
        }&status=${data.advancedSearchItems.status
        }&start_date=${data.advancedSearchItems.startDate
        }&end_date=${data.advancedSearchItems.endDate
        }&institution=${data.advancedSearchItems.institution
        }&client=${data.advancedSearchItems.client
        }&price=${data.advancedSearchItems.price}`,
      )
        .then((response) => {
          console.log(response);
          this.totalItems = response.count;
          this.sales = response.sales;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    details(data) {
      this.$emit('details', data);
    },
    returnE(data) {
      this.$emit('return', data);
    },
    movement(data) {
      this.$emit('movement', data);
    },
    print(data) {
      this.$emit('print', data);
    },
    showImages(id, images, date) {
      this.$emit('images', {
        type: 'string', id, images, date,
      });
    },
    showResImages(id, images) {
      this.$emit('images', {
        type: 'string', id, images, path: 'reservations',
      });
    },
    updatePagination() {
      this.activeRow = null;
    },
    saveNotes(id, notes) {
      putRequest('/sale/notes', `id=${id}`, { notes })
        .then(() => { this.refreshSales(); })
        .catch((error) => { console.log(error); });
    },
    refreshSales() {
      if (this.searchObject.type === null) {
          this.getSales();
        } else if (this.searchObject.type === 'regularSearch') {
          this.regularSearch(this.searchObject.data);
        } else if (this.searchObject.type === 'advancedSearch') {
          this.advancedSearch(this.searchObject.data);
        } else {
          console.log('search type is invalid');
        }
    },
    getPosition(code) {
      switch (code) {
        case 'S001':
          return this.$i18n.t('left');
        case 'S002':
          return this.$i18n.t('right');
        default:
          return '';
      }
    },
    difference(value) {
      const diff = value.toString();
      return diff.substring(0, 6);
    },
    openQrDialog(data) {
      this.$emit('qr', data);
    },
  },
};
</script>
